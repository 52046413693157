function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

import GLOBAL from "./global";
import DOM from "./dom";
import ERROR from "./error";

var ERRORLOG = function ERRORLOG(adsid, ercode, description, methodname) {
  var streamURL = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "";
  var erUrl = "https://mercury.akamaized.net/web/".concat(GLOBAL.ai, "/err.gif?");
  var title = ERROR[ercode] ? ERROR[ercode] : "";
  var date = new Date();
  var dateStr = date.getFullYear() + "-" + ("00" + date.getDate()).slice(-2) + "-" + ("00" + (date.getMonth() + 1)).slice(-2) + " " + ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2) + ":" + ("00" + date.getSeconds()).slice(-2);
  console.log(dateStr);
  var edata = description;

  if (_typeof(edata) === "object") {
    edata = JSON.stringify(edata);
  } else {
    edata = edata;
  }

  var eCode = ercode;
  var mthn = methodname;
  var erUrlu = "";
  erUrlu = "".concat(erUrl, "mth=").concat(mthn, "&uid=").concat(GLOBAL.uid, "&des=").concat(edata, "&package=").concat(GLOBAL.ai, "&code=").concat(eCode, "&pt=").concat(GLOBAL.platform, "&adspot=").concat(adsid ? adsid : "", "&advid=").concat(GLOBAL.ifa, "&title=").concat(title, "&ts=").concat(dateStr, "&dvm=web&av=").concat(GLOBAL.av, "&vr=").concat(GLOBAL.vr, "&dvb=web&osv=").concat(GLOBAL.osv, "&viewUrl=").concat(streamURL);
  var xhe = new XMLHttpRequest();
  xhe.open("GET", erUrlu, true);
  xhe.send();
};

export default ERRORLOG;