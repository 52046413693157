import GLOBAL from "./global";
import SDK from "./SDKLog";
import UTILS from "./util";
var FW = {};

FW.createStdEvent = function (eventName, element) {
  var event;

  if (element) {
    if (typeof window.Event === "function") {
      try {
        event = new Event(eventName);
        element.dispatchEvent(event);
      } catch (e) {
        SDK.trace(e);
      }
    } else {
      try {
        event = document.createEvent("Event");
        event.initEvent(eventName, true, true);
        element.dispatchEvent(event);
      } catch (e) {
        SDK.trace(e);
      }
    }
  }
};

FW.vastReadableTime = function (time) {
  if (typeof time !== "undefined" && typeof time === "number" && Number.isFinite(time) && time >= 0) {
    var seconds = 0;
    var minutes = 0;
    var hours = 0;
    var ms = Math.floor(time % 1000);

    if (ms === 0) {
      ms = "000";
    } else if (ms < 10) {
      ms = "00" + ms;
    } else if (ms < 100) {
      ms = "0" + ms;
    } else {
      ms = ms.toString();
    }

    seconds = Math.floor(time * 1.0 / 1000);

    if (seconds > 59) {
      minutes = Math.floor(seconds * 1.0 / 60);
      seconds = seconds - minutes * 60;
    }

    if (seconds === 0) {
      seconds = "00";
    } else if (seconds < 10) {
      seconds = "0" + seconds;
    } else {
      seconds = seconds.toString();
    }

    if (minutes > 59) {
      hours = Math.floor(minutes * 1.0 / 60);
      minutes = minutes - hours * 60;
    }

    if (minutes === 0) {
      minutes = "00";
    } else if (minutes < 10) {
      minutes = "0" + minutes;
    } else {
      minutes = minutes.toString();
    }

    if (hours === 0) {
      hours = "00";
    } else if (hours < 10) {
      hours = "0" + hours;
    } else {
      if (hours > 23) {
        hours = "00";
      } else {
        hours = hours.toString();
      }
    }

    return hours + ":" + minutes + ":" + seconds + "." + ms;
  } else {
    return "00:00:00.000";
  }
};

FW.generateCacheBusting = function () {
  var length = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 8;
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789" + Math.floor(Date.now());

  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

FW.getNodeValue = function (element, http) {
  var childNodes = element.childNodes;
  var value = ""; // sometimes we have may several nodes - some of which may hold whitespaces

  for (var i = 0, len = childNodes.length; i < len; i++) {
    if (childNodes[i] && childNodes[i].textContent) {
      value += childNodes[i].textContent.trim();
    }
  }

  if (value) {
    // in case we have some leftovers CDATA - mainly for VPAID
    var pattern = /^<!\[CDATA\[.*\]\]>$/i;

    if (pattern.test(value)) {
      value = value.replace("<![CDATA[", "").replace("]]>", "");
    }

    if (http) {
      var httpPattern = /^(https?:)?\/\//i;

      if (httpPattern.test(value)) {
        return value;
      }
    } else {
      return value;
    }
  }

  return null;
};

FW.RFC3986EncodeURIComponent = function (str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
};

FW.isValidDuration = function (duration) {
  // HH:MM:SS or HH:MM:SS.mmm
  var skipPattern = /^\d+:\d+:\d+(\.\d+)?$/i;

  if (skipPattern.test(duration)) {
    return true;
  }

  return false;
};

FW.convertDurationToSeconds = function (duration) {
  // duration is HH:MM:SS or HH:MM:SS.mmm
  // remove .mmm
  var splitNoMS = duration.split(".");
  splitNoMS = splitNoMS[0];
  var splitTime = splitNoMS.split(":");
  var seconds = 0;
  seconds = parseInt(splitTime[0]) * 60 * 60 + parseInt(splitTime[1]) * 60 + parseInt(splitTime[2]);
  return seconds;
}; // HH:MM:SS or HH:MM:SS.mmm


var skipPattern1 = /^\d+:\d+:\d+(\.\d+)?$/i; // n%

var skipPattern2 = /^\d+%$/i;

FW.isValidOffset = function (offset) {
  if (skipPattern1.test(offset) || skipPattern2.test(offset)) {
    return true;
  }

  return false;
};

FW.convertOffsetToSeconds = function (offset, duration) {
  var seconds = 0;

  if (skipPattern1.test(offset)) {
    // remove .mmm
    var splitNoMS = offset.split(".");
    splitNoMS = splitNoMS[0];
    var splitTime = splitNoMS.split(":");
    seconds = parseInt(splitTime[0]) * 60 * 60 + parseInt(splitTime[1]) * 60 + parseInt(splitTime[2]);
  } else if (skipPattern2.test(offset) && duration > 0) {
    var percent = offset.split("%");
    percent = parseInt(percent[0]);
    seconds = Math.round(duration * percent / 100 / 1000);
  }

  return seconds;
};

FW.logVideoEvents = function (video, type) {
  var events = ["loadstart", "durationchange", "loadedmetadata", "loadeddata", "canplay", "canplaythrough"];
  events.forEach(function (value) {
    video.addEventListener(value, function (e) {
      if (e && e.type) {
        SDK.log(type + " player event - " + e.type);
      }
    });
  });
};

FW.openWindow = function (link) {
  try {
    link = link.indexOf("://") === -1 ? "https://" + link : link; //Adding https:// by default if no protocol exists

    window.open(link, "width=".concat(GLOBAL.sw, ", height=").concat(GLOBAL.sh, ", scrollbars=yes"));
  } catch (e) {
    SDK.trace(e);
  }
};

FW.openInApp = function (link) {
  try {
    link = link.indexOf("://") === -1 ? "https://" + link : link; //Adding https:// by default if no protocol exists

    window.location.assign(link);
  } catch (e) {
    SDK.trace(e);
  }
};

FW.click = function (url, openInAp) {
  var isDeeplinking = false;
  var isInApp = false;
  if (!window.focus) return true;
  var href = typeof url == "string" ? url : url.href;
  if (href.indexOf(GLOBAL.deeplinkingHostName) !== -1) isDeeplinking = true;

  if (UTILS.clickControlEnabled() === true) {
    isInApp = true;
  }

  if (!GLOBAL.clkSelf) {
    try {
      if (!isInApp || isDeeplinking) {
        var aOptions = FW.setOptions(href);
        SDK.debug(aOptions);
        var openURL = new MozActivity(aOptions);

        openURL.onerror = function (err) {
          SDK.info("Failed to openURL activity!", err);
        };

        openURL.onsuccess = function (eData) {
          SDK.debug("App Invocaked...", eData);
        };
      } else if (UTILS.isBrandPageURL(url)) {
        UTILS.renderBrandPage(url);
      } else if (openInAp) {
        FW.openInApp(href);
      } else {
        FW.openWindow(href);
      }

      SDK.debug("PopUp open called", href);
    } catch (error) {
      SDK.log("Cant able to find MozActivity", error);
    }
  }

  return false;
};

FW.setOptions = function (href) {
  var defaultOptions = {
    name: "view",
    data: {
      type: "url",
      url: href
    }
  };
  var pUriNode = document.createElement("a");
  var cTracker = "";
  pUriNode.style = "display: none;";
  pUriNode.href = decodeURI(href);
  var pUriParams = FW.getUriParams(pUriNode, "__");
  href = pUriParams.hasOwnProperty("r") ? decodeURIComponent(pUriParams["r"]) : pUriNode.href;
  cTracker = pUriNode.href.replace("__r=" + pUriParams["r"], "");
  var tUriNode = document.createElement("a");
  tUriNode.href = href;

  if (tUriNode.hostname === GLOBAL.deeplinkingHostName) {
    if (cTracker && cTracker.indexOf(GLOBAL.deeplinkingHostName) === -1) {
      var i1 = new Image();
      i1.src = cTracker;
      document.body.appendChild(i1);
    }

    var uPathname = tUriNode.pathname.indexOf("/") !== -1 ? tUriNode.pathname.replace("/", "") : tUriNode.pathname;
    return {
      name: uPathname,
      data: FW.getUriParams(tUriNode)
    };
  }

  return defaultOptions;
};

FW.getUriParams = function (tUriNode, pSeperator) {
  var uParams = {};
  var arr;
  var uKVPair = tUriNode.search && tUriNode.search.indexOf("?") === 0 ? tUriNode.search.replace("?", "").split(pSeperator || "&") : tUriNode.search;

  for (var i in uKVPair) {
    if (uKVPair.hasOwnProperty(i)) {
      arr = uKVPair[i].split("=");
      uParams[arr[0]] = arr[1];
    }
  }

  return uParams || null;
};

FW.imagePattern = /^image\/(gif|jpeg|jpg|png)$/i;
export default FW;