function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

import FCAP from "./../business/fcap";
import SDK from "./../../utils/SDKLog";
import UTILS from "./../../utils/util";
import GLOBAL from "./../../utils/global";
import storage from "./../../utils/storage";
import getResponseHeader from "./reponseHeader";
import Video from "./../../view/instream/video";
import Banner from "./../../view/native/banner";
import BillboardImage from "./../../view/native/billboard";
import BillboardDisplay from "./../../view/native/billboarddisplay";
import BillboardVideo from "./../../view/native/billboardvideo";
import CustomAd from "./../../view/native/custom";
import InterstitialImage from "./../../view/native/interstitial";
import InterstitialDisplay from "./../../view/native/interstitialdisplay";
import InterstitialVideo from "./../../view/native/interstitialvideo";
import RewardedVideo from "../../view/rewarded/rewardedvideo";
import AdsequencingManager from "./../business/AdsequencingManager";
import ERROR from "./../../utils/error";
import CALLBACK from "./../../interface/callback";

var adFormatSelection = function adFormatSelection(options) {
  var ad = options.ad,
      xhr = options.xhr,
      data = options.data;
  var STORAGE = new storage();

  var _ref = xhr.hasOwnProperty("adspotType") ? xhr : getResponseHeader({
    xhr: xhr
  }),
      delay = _ref.delay,
      adspotType = _ref.adspotType,
      _ref$block = _ref.block,
      block = _ref$block === void 0 ? 0 : _ref$block,
      jioCSL = _ref.jioCSL,
      _ref$refreshTime = _ref.refreshTime,
      refreshTime = _ref$refreshTime === void 0 ? 0 : _ref$refreshTime,
      _ref$orientation = _ref.orientation,
      orientation = _ref$orientation === void 0 ? 0 : _ref$orientation,
      _ref$rewardIn = _ref.rewardIn,
      rewardIn = _ref$rewardIn === void 0 ? 0 : _ref$rewardIn,
      _ref$mid = _ref.mid,
      mid = _ref$mid === void 0 ? {} : _ref$mid,
      _ref$adSeq = _ref.adSeq,
      adSeq = _ref$adSeq === void 0 ? {} : _ref$adSeq,
      _ref$fcap = _ref.fcap,
      fcap = _ref$fcap === void 0 ? "{}" : _ref$fcap,
      _ref$contentType = _ref.contentType,
      contentType = _ref$contentType === void 0 ? null : _ref$contentType,
      _ref$fmt = _ref.fmt,
      fmt = _ref$fmt === void 0 ? null : _ref$fmt,
      _ref$inl = _ref.inl,
      inl = _ref$inl === void 0 ? 0 : _ref$inl,
      _ref$te = _ref.te,
      te = _ref$te === void 0 ? 0 : _ref$te,
      _ref$oia = _ref.oia,
      oia = _ref$oia === void 0 ? 0 : _ref$oia,
      _ref$wh = _ref.wh,
      wh = _ref$wh === void 0 ? "" : _ref$wh,
      _ref$loc = _ref.loc,
      loc = _ref$loc === void 0 ? "" : _ref$loc,
      _ref$vim = _ref.vim,
      vim = _ref$vim === void 0 ? "" : _ref$vim,
      _ref$vce = _ref.vce,
      vce = _ref$vce === void 0 ? "{\"type\":\"0\",\"time\":\"0\"}" : _ref$vce;

  var type = ad.type,
      _ad$mod = ad.mod,
      mod = _ad$mod === void 0 ? {} : _ad$mod;
  var placementId = data.placementId,
      loadAd = data.loadAd,
      adspotKey = data.adspotKey,
      ai = data.ai,
      _data$ccbString = data.ccbString,
      ccbString = _data$ccbString === void 0 ? false : _data$ccbString;
  delay = parseInt(delay);
  block = parseInt(block);
  mid = UTILS.jsonParser(mid);
  fcap = UTILS.jsonParser(fcap);
  inl = parseInt(inl);
  vce = UTILS.jsonParser(vce);
  oia = parseInt(oia);
  loc = UTILS.jsonParser(loc);
  adSeq = UTILS.jsonParser(adSeq);
  var refreshRate = parseInt(refreshTime);

  if (mid && mid.hasOwnProperty("id")) {
    var adspotMaskingDetails = STORAGE.getItem("maskingInfo") || {};

    if (mid.hasOwnProperty("type") && mid["type"] == 1) {
      adspotMaskingDetails["UUID"] = mid;
    } else {
      adspotMaskingDetails[ai] = mid;
    }

    STORAGE.setItem("maskingInfo", adspotMaskingDetails, "local");
  }

  if (block) {
    var blockedUpto = new Date(new Date().getTime() + block * 60000);
    STORAGE.setItem("blockAdRequest_" + adspotKey, {
      adspot: adspotKey,
      blockUpto: blockedUpto
    }, "local");
    SDK.warn("Ad request blocked further for this Adspot: " + adspotKey + " upto: " + blockedUpto);
  }

  var adid = xhr["adId"] ? xhr["adId"] : "";
  var campId = xhr["campaignId"] ? xhr["campaignId"] : "";
  /*console.log("jkvjh", adid, campId);*/

  var placementNode = document.getElementById(placementId);
  if (!placementNode) return; //@TODO BY AMARJEET Error fixing

  var dataCustomization = placementNode.getAttribute("data-customization") || 0;
  var containerId = placementNode.getAttribute("data-container-id");
  var compDivInfo = placementNode.getAttribute("companion-container");
  if (GLOBAL.AdManager[placementId].isMultiAdEnable() == false && placementNode.hasAttribute("data-fcap")) placementNode.removeAttribute("data-fcap");

  if (fcap && fcap.hasOwnProperty("campaignid") && fcap.hasOwnProperty("mod") && fcap["mod"].hasOwnProperty("fcr")) {
    var fcr = fcap["mod"]["fcr"],
        campaignid = fcap["campaignid"];
    placementNode.setAttribute("data-fcap", campaignid);
    SDK.log("Response FCAP : " + JSON.stringify(fcr));
    FCAP.addCampaign(campaignid, fcr);
  }

  if (loc) {
    STORAGE.setItem("x_jio_location_data", loc);
    GLOBAL.loc = STORAGE.getItem("x_jio_location_data");
    GLOBAL.md_cn = loc.cn ? loc.cn : "";
    GLOBAL.md_st = loc.st ? loc.st : "";
    GLOBAL.md_ct = loc.ct ? loc.ct : "";
    GLOBAL.md_pn = loc.pn ? loc.pn : "";
  }

  if (adspotType == "5" || adspotType == "11") {
    //instream
    if (dataCustomization) type = 13;else type = 12;
  } else if (adspotType == "6" || adspotType == "1") {
    //interstitial
    type = 4;
  } else if (adspotType == "8") {
    //billboard
    if (dataCustomization) type = 13;else type = 3;
  } else if (adspotType == "7") {
    //banner
    if (dataCustomization) type = 13;else type = 2;
  } else if (adspotType == "9") //Rewarded
    type = 9;else if (adspotType == "10") //Dynamic Display
    type = 10;

  SDK.log("Ad format selection for Ad type: " + type);
  if (adSeq) AdsequencingManager.setConfig(adspotKey, adspotType, adSeq); //////// SWITCH CASE

  var adFormat = null;
  var vastad = null;
  var result = null;

  switch (type) {
    case 2:
      SDK.log("Ad format selected type: " + type + " banner / in-feed");
      GLOBAL.adObject[placementId] = new Banner({
        ad: ad,
        loadAd: loadAd,
        placementId: placementId,
        refreshRate: refreshRate,
        ccbString: ccbString,
        vce: vce,
        campId: campId,
        adid: adid,
        oia: oia
      });
      break;

    case 3:
      SDK.log("Ad format selected type: " + type + " billboard / content stream");

      if (contentType === "text/html; charset=UTF-8" || fmt == "2") {} else if (contentType === "application/xml; charset=UTF-8" || fmt == "1") {
        vastad = ad;
      } else {
        try {
          adFormat = JSON.parse(ad);
        } catch (_unused) {
          vastad = ad;
        }

        if (vastad == null) {
          result = adFormat.hasOwnProperty("result") ? adFormat.result : adFormat;
          vastad = result.hasOwnProperty("video") ? result.video : null;
        }
      }

      if (vastad) {
        SDK.log("dynamic display video");
        GLOBAL.adObject[placementId] = new BillboardVideo({
          vastAd: vastad,
          loadAd: loadAd,
          placementId: placementId,
          ad: ad,
          delay: delay,
          refreshRate: refreshRate,
          ccbString: ccbString,
          vce: vce,
          oia: oia,
          campId: campId,
          adid: adid
        });
      } else if (contentType === "text/html; charset=UTF-8" || fmt == "2") {
        SDK.log("dynamic display html");
        GLOBAL.adObject[placementId] = new BillboardDisplay({
          ad: ad,
          loadAd: loadAd,
          placementId: placementId,
          refreshRate: refreshRate,
          ccbString: ccbString,
          wh: wh,
          vce: vce,
          containerId: containerId,
          oia: oia,
          vim: vim,
          campId: campId,
          adid: adid
        });
      } else {
        SDK.log("dynamic display image");
        GLOBAL.adObject[placementId] = new BillboardImage({
          ad: ad,
          loadAd: loadAd,
          placementId: placementId,
          refreshRate: refreshRate,
          ccbString: ccbString,
          vce: vce,
          campId: campId,
          adid: adid,
          oia: oia
        });
      }

      break;

    case 4:
      {
        SDK.log("Ad format selected type: " + type + " native intertital");

        if (contentType === "text/html; charset=UTF-8" || fmt == "2") {} else if (contentType === "application/xml; charset=UTF-8" || fmt == "1") {
          vastad = ad;
        } else {
          try {
            adFormat = JSON.parse(ad);
          } catch (_unused2) {
            vastad = ad;
          }

          if (vastad == null) {
            result = adFormat.hasOwnProperty("result") ? adFormat.result : adFormat;
            vastad = result.hasOwnProperty("video") ? result.video : null;
          }
        }

        if (vastad) {
          SDK.log("native intertital video");
          GLOBAL.adObject[placementId] = new InterstitialVideo({
            vastAd: vastad,
            loadAd: loadAd,
            placementId: placementId,
            modalAd: true,
            ad: ad,
            delay: delay,
            ccbString: ccbString,
            vce: vce,
            campId: campId,
            adid: adid,
            oia: oia
          });
        } else if (contentType === "text/html; charset=UTF-8" || fmt == "2") {
          SDK.log("native intertital html");
          GLOBAL.adObject[placementId] = new InterstitialDisplay({
            ad: ad,
            loadAd: loadAd,
            placementId: placementId,
            modalAd: true,
            inl: inl,
            ccbString: ccbString,
            wh: wh,
            vce: vce,
            containerId: containerId,
            oia: oia,
            vim: vim,
            campId: campId,
            delay: delay,
            adid: adid
          });
        } else {
          SDK.log("native intertital image");
          GLOBAL.adObject[placementId] = new InterstitialImage({
            ad: ad,
            loadAd: loadAd,
            placementId: placementId,
            ccbString: ccbString,
            modalAd: true,
            vce: vce,
            oia: oia,
            campId: campId,
            adid: adid
          });
        }
      }
      break;

    case 10:
      {
        SDK.log("Ad format selected type: " + type + " dynamic display");

        if (contentType === "text/html; charset=UTF-8" || fmt == "2") {} else if (contentType === "application/xml; charset=UTF-8" || fmt == "1") {
          vastad = ad;
        } else {
          try {
            adFormat = JSON.parse(ad);
          } catch (_unused3) {
            vastad = ad;
          }

          if (vastad == null) {
            result = adFormat.hasOwnProperty("result") ? adFormat.result : adFormat;
            vastad = result.hasOwnProperty("video") ? result.video : null;
          }
        }

        if (vastad) {
          SDK.log("dynamic display video");
          GLOBAL.adObject[placementId] = new InterstitialVideo({
            vastAd: vastad,
            loadAd: loadAd,
            placementId: placementId,
            ad: ad,
            delay: delay,
            ccbString: ccbString,
            vce: vce,
            oia: oia,
            campId: campId,
            adid: adid
          });
        } else if (contentType === "text/html; charset=UTF-8" || fmt == "2") {
          SDK.log("dynamic display html");
          GLOBAL.adObject[placementId] = new InterstitialDisplay({
            ad: ad,
            loadAd: loadAd,
            placementId: placementId,
            ccbString: ccbString,
            refreshRate: refreshRate,
            wh: wh,
            vce: vce,
            containerId: containerId,
            inl: inl,
            oia: oia,
            vim: vim,
            campId: campId,
            adid: adid
          });
        } else {
          SDK.log("dynamic display image");
          GLOBAL.adObject[placementId] = new InterstitialImage({
            ad: ad,
            loadAd: loadAd,
            placementId: placementId,
            ccbString: ccbString,
            vce: vce,
            oia: oia,
            campId: campId,
            adid: adid
          });
        }
      }
      break;

    case 13:
      SDK.log("Ad format selected type: " + type + " custom Ad");

      try {
        adFormat = JSON.parse(ad);
      } catch (_unused4) {
        vastad = ad;
      }

      if (vastad == null) {
        result = adFormat.hasOwnProperty("result") ? adFormat.result : adFormat;
        vastad = result.hasOwnProperty("video") ? result.video : null;
      }

      if (typeof JioAds.onAdsReceived === "function") {
        CALLBACK.onAdsReceived({
          placementId: placementId,
          adResponse: adFormat,
          vastad: vastad
        });
      } else {
        GLOBAL.adObject[placementId] = new CustomAd({
          vastAd: vastad,
          ad: ad,
          loadAd: loadAd,
          delay: delay,
          placementId: placementId,
          refreshRate: refreshRate,
          ccbString: ccbString,
          vce: vce,
          campId: campId,
          adid: adid,
          oia: oia
        });
      }

      break;

    case 12:
      SDK.log("Ad format selected type: " + type + " instream video");
      GLOBAL.adObject[placementId] = new Video({
        vastAd: ad,
        loadAd: loadAd,
        placementId: placementId,
        ad: ad,
        delay: delay,
        containerId: containerId,
        compDivInfo: compDivInfo,
        ccbString: ccbString,
        vce: vce,
        campId: campId,
        adid: adid,
        oia: oia,
        adspotKey: adspotKey
      });
      break;

    case 9:
      SDK.log("Ad format selected type: " + type + " rewarded video");

      try {
        adFormat = JSON.parse(ad);
      } catch (_unused5) {
        vastad = ad;
      }

      if (vastad == null) {
        result = adFormat.hasOwnProperty("result") ? adFormat.result : adFormat;
        vastad = result.hasOwnProperty("video") ? result.video : null;
      }

      GLOBAL.adObject[placementId] = new RewardedVideo({
        vastAd: vastad,
        loadAd: loadAd,
        placementId: placementId,
        rewardIn: rewardIn,
        ad: ad,
        ccbString: ccbString,
        vce: vce,
        campId: campId,
        adid: adid,
        delay: delay,
        oia: oia
      });
      break;

    default:
      var response = UTILS.jsonParser(ad);
      var error = _typeof(response) == "object" && Object.keys(response).length ? response.errors[0]["errorMsg"] : "invalid ad format selection type";
      CALLBACK.onAdFailedToLoad({
        placementId: placementId,
        errorCode: "m107",
        response: "invalid ad format selection type",
        methodname: "adFormatSelection"
      });
      break;
  }

  if (GLOBAL.adObject[placementId]) {
    SDK.log("Ad format selected and going to render");
    GLOBAL.adObject[placementId].render();
  }
};

export default adFormatSelection;