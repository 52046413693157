var lang_code_to_language = {
  AF: "Afrikaans",
  "AF-NA": "Afrikaans",
  "AF-ZA": "Afrikaans",
  AGQ: "Aghem",
  "AGQ-CM": "Aghem",
  AK: "Akan",
  "AK-GH": "Akan",
  AM: "Amharic",
  "AM-ET": "Amharic",
  AR: "Arabic",
  ARS: "ars",
  "AR-001": "Arabic",
  "AR-AE": "Arabic",
  "AR-BH": "Arabic",
  "AR-DJ": "Arabic",
  "AR-DZ": "Arabic",
  "AR-EG": "Arabic",
  "AR-EH": "Arabic",
  "AR-ER": "Arabic",
  "AR-IL": "Arabic",
  "AR-IQ": "Arabic",
  "AR-JO": "Arabic",
  "AR-KM": "Arabic",
  "AR-KW": "Arabic",
  "AR-LB": "Arabic",
  "AR-LY": "Arabic",
  "AR-MA": "Arabic",
  "AR-MR": "Arabic",
  "AR-OM": "Arabic",
  "AR-PS": "Arabic",
  "AR-QA": "Arabic",
  "AR-SA": "Arabic",
  "AR-SD": "Arabic",
  "AR-SO": "Arabic",
  "AR-SS": "Arabic",
  "AR-SY": "Arabic",
  "AR-TD": "Arabic",
  "AR-TN": "Arabic",
  "AR-YE": "Arabic",
  AS: "Assamese",
  ASA: "Asu",
  "ASA-TZ": "Asu",
  AST: "Asturian",
  "AST-ES": "Asturian",
  "AS-IN": "Assamese",
  AZ: "Azerbaijani",
  "AZ-CYRL": "Azerbaijani",
  "AZ-CYRL-AZ": "Azerbaijani",
  "AZ-LATN": "Azerbaijani",
  "AZ-LATN-AZ": "Azerbaijani",
  BAS: "Basaa",
  "BAS-CM": "Basaa",
  BE: "Belarusian",
  BEM: "Bemba",
  "BEM-ZM": "Bemba",
  BEZ: "Bena",
  "BEZ-TZ": "Bena",
  "BE-BY": "Belarusian",
  BG: "Bulgarian",
  "BG-BG": "Bulgarian",
  BM: "Bambara",
  "BM-ML": "Bambara",
  BN: "Bengali",
  "BN-BD": "Bengali",
  "BN-IN": "Bengali",
  BO: "Tibetan",
  "BO-CN": "Tibetan",
  "BO-IN": "Tibetan",
  BR: "Breton",
  BRX: "Bodo",
  "BRX-IN": "Bodo",
  "BR-FR": "Breton",
  BS: "Bosnian",
  "BS-CYRL": "Bosnian",
  "BS-CYRL-BA": "Bosnian",
  "BS-LATN": "Bosnian",
  "BS-LATN-BA": "Bosnian",
  CA: "Catalan",
  "CA-AD": "Catalan",
  "CA-ES": "Catalan",
  "CA-FR": "Catalan",
  "CA-IT": "Catalan",
  CE: "Chechen",
  "CE-RU": "Chechen",
  CGG: "Chiga",
  "CGG-UG": "Chiga",
  CHR: "Cherokee",
  "CHR-US": "Cherokee",
  CKB: "Sorani Kurdish",
  "CKB-IQ": "Sorani Kurdish",
  "CKB-IR": "Sorani Kurdish",
  CS: "Czech",
  "CS-CZ": "Czech",
  CY: "Welsh",
  "CY-GB": "Welsh",
  DA: "Danish",
  DAV: "Taita",
  "DAV-KE": "Taita",
  "DA-DK": "Danish",
  "DA-GL": "Danish",
  DE: "German",
  "DE-AT": "German",
  "DE-BE": "German",
  "DE-CH": "German",
  "DE-DE": "German",
  "DE-LI": "German",
  "DE-LU": "German",
  DJE: "Zarma",
  "DJE-NE": "Zarma",
  DSB: "Lower Sorbian",
  "DSB-DE": "Lower Sorbian",
  DUA: "Duala",
  "DUA-CM": "Duala",
  DYO: "Jola-Fonyi",
  "DYO-SN": "Jola-Fonyi",
  DZ: "Dzongkha",
  "DZ-BT": "Dzongkha",
  EBU: "Embu",
  "EBU-KE": "Embu",
  EE: "Ewe",
  "EE-GH": "Ewe",
  "EE-TG": "Ewe",
  EL: "Greek",
  "EL-CY": "Greek",
  "EL-GR": "Greek",
  EN: "English",
  "EN-001": "English",
  "EN-150": "English",
  "EN-AD": "English",
  "EN-AG": "English",
  "EN-AI": "English",
  "EN-AL": "English",
  "EN-AS": "English",
  "EN-AT": "English",
  "EN-AU": "English",
  "EN-BA": "English",
  "EN-BB": "English",
  "EN-BE": "English",
  "EN-BI": "English",
  "EN-BM": "English",
  "EN-BR": "English",
  "EN-BS": "English",
  "EN-BW": "English",
  "EN-BZ": "English",
  "EN-CA": "English",
  "EN-CC": "English",
  "EN-CH": "English",
  "EN-CK": "English",
  "EN-CM": "English",
  "EN-CN": "English",
  "EN-CX": "English",
  "EN-CY": "English",
  "EN-CZ": "English",
  "EN-DE": "English",
  "EN-DG": "English",
  "EN-DK": "English",
  "EN-DM": "English",
  "EN-EE": "English",
  "EN-ER": "English",
  "EN-ES": "English",
  "EN-FI": "English",
  "EN-FJ": "English",
  "EN-FK": "English",
  "EN-FM": "English",
  "EN-FR": "English",
  "EN-GB": "English",
  "EN-GD": "English",
  "EN-GG": "English",
  "EN-GH": "English",
  "EN-GI": "English",
  "EN-GM": "English",
  "EN-GR": "English",
  "EN-GU": "English",
  "EN-GY": "English",
  "EN-HK": "English",
  "EN-HR": "English",
  "EN-HU": "English",
  "EN-IE": "English",
  "EN-IL": "English",
  "EN-IM": "English",
  "EN-IN": "English",
  "EN-IO": "English",
  "EN-IS": "English",
  "EN-IT": "English",
  "EN-JE": "English",
  "EN-JM": "English",
  "EN-JP": "English",
  "EN-KE": "English",
  "EN-KI": "English",
  "EN-KN": "English",
  "EN-KR": "English",
  "EN-KY": "English",
  "EN-LC": "English",
  "EN-LR": "English",
  "EN-LS": "English",
  "EN-LT": "English",
  "EN-LU": "English",
  "EN-LV": "English",
  "EN-ME": "English",
  "EN-MG": "English",
  "EN-MH": "English",
  "EN-MO": "English",
  "EN-MP": "English",
  "EN-MS": "English",
  "EN-MT": "English",
  "EN-MU": "English",
  "EN-MV": "English",
  "EN-MW": "English",
  "EN-MY": "English",
  "EN-NA": "English",
  "EN-NF": "English",
  "EN-NG": "English",
  "EN-NL": "English",
  "EN-NO": "English",
  "EN-NR": "English",
  "EN-NU": "English",
  "EN-NZ": "English",
  "EN-PG": "English",
  "EN-PH": "English",
  "EN-PK": "English",
  "EN-PL": "English",
  "EN-PN": "English",
  "EN-PR": "English",
  "EN-PT": "English",
  "EN-PW": "English",
  "EN-RO": "English",
  "EN-RU": "English",
  "EN-RW": "English",
  "EN-SB": "English",
  "EN-SC": "English",
  "EN-SD": "English",
  "EN-SE": "English",
  "EN-SG": "English",
  "EN-SH": "English",
  "EN-SI": "English",
  "EN-SK": "English",
  "EN-SL": "English",
  "EN-SS": "English",
  "EN-SX": "English",
  "EN-SZ": "English",
  "EN-TC": "English",
  "EN-TK": "English",
  "EN-TO": "English",
  "EN-TR": "English",
  "EN-TT": "English",
  "EN-TV": "English",
  "EN-TW": "English",
  "EN-TZ": "English",
  "EN-UG": "English",
  "EN-UM": "English",
  "EN-US": "English",
  "EN-US-POSIX": "English",
  "EN-VC": "English",
  "EN-VG": "English",
  "EN-VI": "English",
  "EN-VU": "English",
  "EN-WS": "English",
  "EN-ZA": "English",
  "EN-ZM": "English",
  "EN-ZW": "English",
  EO: "Esperanto",
  ES: "Spanish",
  "ES-419": "Spanish",
  "ES-AR": "Spanish",
  "ES-BO": "Spanish",
  "ES-BZ": "Spanish",
  "ES-CL": "Spanish",
  "ES-CO": "Spanish",
  "ES-CR": "Spanish",
  "ES-CU": "Spanish",
  "ES-DO": "Spanish",
  "ES-EA": "Spanish",
  "ES-EC": "Spanish",
  "ES-ES": "Spanish",
  "ES-GQ": "Spanish",
  "ES-GT": "Spanish",
  "ES-HN": "Spanish",
  "ES-IC": "Spanish",
  "ES-MX": "Spanish",
  "ES-NI": "Spanish",
  "ES-PA": "Spanish",
  "ES-PE": "Spanish",
  "ES-PH": "Spanish",
  "ES-PR": "Spanish",
  "ES-PY": "Spanish",
  "ES-SV": "Spanish",
  "ES-US": "Spanish",
  "ES-UY": "Spanish",
  "ES-VE": "Spanish",
  ET: "Estonian",
  "ET-EE": "Estonian",
  EU: "Basque",
  "EU-ES": "Basque",
  EWO: "Ewondo",
  "EWO-CM": "Ewondo",
  FA: "Persian",
  "FA-AF": "Persian",
  "FA-IR": "Persian",
  FF: "Fulah",
  "FF-CM": "Fulah",
  "FF-GN": "Fulah",
  "FF-MR": "Fulah",
  "FF-SN": "Fulah",
  FI: "Finnish",
  FIL: "Filipino",
  "FIL-PH": "Filipino",
  "FI-FI": "Finnish",
  FO: "Faroese",
  "FO-DK": "Faroese",
  "FO-FO": "Faroese",
  FR: "French",
  "FR-BE": "French",
  "FR-BF": "French",
  "FR-BI": "French",
  "FR-BJ": "French",
  "FR-BL": "French",
  "FR-CA": "French",
  "FR-CD": "French",
  "FR-CF": "French",
  "FR-CG": "French",
  "FR-CH": "French",
  "FR-CI": "French",
  "FR-CM": "French",
  "FR-DJ": "French",
  "FR-DZ": "French",
  "FR-FR": "French",
  "FR-GA": "French",
  "FR-GF": "French",
  "FR-GN": "French",
  "FR-GP": "French",
  "FR-GQ": "French",
  "FR-HT": "French",
  "FR-KM": "French",
  "FR-LU": "French",
  "FR-MA": "French",
  "FR-MC": "French",
  "FR-MF": "French",
  "FR-MG": "French",
  "FR-ML": "French",
  "FR-MQ": "French",
  "FR-MR": "French",
  "FR-MU": "French",
  "FR-NC": "French",
  "FR-NE": "French",
  "FR-PF": "French",
  "FR-PM": "French",
  "FR-RE": "French",
  "FR-RW": "French",
  "FR-SC": "French",
  "FR-SN": "French",
  "FR-SY": "French",
  "FR-TD": "French",
  "FR-TG": "French",
  "FR-TN": "French",
  "FR-VU": "French",
  "FR-WF": "French",
  "FR-YT": "French",
  FUR: "Friulian",
  "FUR-IT": "Friulian",
  FY: "Western Frisian",
  "FY-NL": "Western Frisian",
  GA: "Irish",
  "GA-IE": "Irish",
  GD: "Scottish Gaelic",
  "GD-GB": "Scottish Gaelic",
  GL: "Galician",
  "GL-ES": "Galician",
  GSW: "Swiss German",
  "GSW-CH": "Swiss German",
  "GSW-FR": "Swiss German",
  "GSW-LI": "Swiss German",
  GU: "Gujarati",
  GUZ: "Gusii",
  "GUZ-KE": "Gusii",
  "GU-IN": "Gujarati",
  GV: "Manx",
  "GV-IM": "Manx",
  HA: "Hausa",
  HAW: "Hawaiian",
  "HAW-US": "Hawaiian",
  "HA-GH": "Hausa",
  "HA-NE": "Hausa",
  "HA-NG": "Hausa",
  HE: "Hebrew",
  "HE-IL": "Hebrew",
  HI: "Hindi",
  "HI-IN": "Hindi",
  HR: "Croatian",
  "HR-BA": "Croatian",
  "HR-HR": "Croatian",
  HSB: "Upper Sorbian",
  "HSB-DE": "Upper Sorbian",
  HU: "Hungarian",
  "HU-HU": "Hungarian",
  HY: "Armenian",
  "HY-AM": "Armenian",
  ID: "Indonesian",
  "ID-ID": "Indonesian",
  IG: "Igbo",
  "IG-NG": "Igbo",
  II: "Sichuan Yi",
  "II-CN": "Sichuan Yi",
  IS: "Icelandic",
  "IS-IS": "Icelandic",
  IT: "Italian",
  "IT-CH": "Italian",
  "IT-IT": "Italian",
  "IT-SM": "Italian",
  IU: "Inuktitut",
  "IU-CA": "Inuktitut",
  JA: "Japanese",
  "JA-JP": "Japanese",
  JGO: "Ngomba",
  "JGO-CM": "Ngomba",
  JMC: "Machame",
  "JMC-TZ": "Machame",
  KA: "Georgian",
  KAB: "Kabyle",
  "KAB-DZ": "Kabyle",
  KAM: "Kamba",
  "KAM-KE": "Kamba",
  "KA-GE": "Georgian",
  KDE: "Makonde",
  "KDE-TZ": "Makonde",
  KEA: "Kabuverdianu",
  "KEA-CV": "Kabuverdianu",
  KHQ: "Koyra Chiini",
  "KHQ-ML": "Koyra Chiini",
  KI: "Kikuyu",
  "KI-KE": "Kikuyu",
  KK: "Kazakh",
  KKJ: "Kako",
  "KKJ-CM": "Kako",
  "KK-KZ": "Kazakh",
  KL: "Kalaallisut",
  KLN: "Kalenjin",
  "KLN-KE": "Kalenjin",
  "KL-GL": "Kalaallisut",
  KM: "Khmer",
  "KM-KH": "Khmer",
  KN: "Kannada",
  "KN-IN": "Kannada",
  KO: "Korean",
  KOK: "Konkani",
  "KOK-IN": "Konkani",
  "KO-KP": "Korean",
  "KO-KR": "Korean",
  KS: "Kashmiri",
  KSB: "Shambala",
  "KSB-TZ": "Shambala",
  KSF: "Bafia",
  "KSF-CM": "Bafia",
  KSH: "Colognian",
  "KSH-DE": "Colognian",
  "KS-IN": "Kashmiri",
  KW: "Cornish",
  "KW-GB": "Cornish",
  KY: "Kirghiz",
  "KY-KG": "Kirghiz",
  LAG: "Langi",
  "LAG-TZ": "Langi",
  LB: "Luxembourgish",
  "LB-LU": "Luxembourgish",
  LG: "Ganda",
  "LG-UG": "Ganda",
  LKT: "lkt",
  "LKT-US": "lkt",
  LN: "Lingala",
  "LN-AO": "Lingala",
  "LN-CD": "Lingala",
  "LN-CF": "Lingala",
  "LN-CG": "Lingala",
  LO: "Lao",
  "LO-LA": "Lao",
  LRC: "lrc",
  "LRC-IQ": "lrc",
  "LRC-IR": "lrc",
  LT: "Lithuanian",
  "LT-LT": "Lithuanian",
  LU: "Luba-Katanga",
  LUO: "Luo",
  "LUO-KE": "Luo",
  LUY: "Luyia",
  "LUY-KE": "Luyia",
  "LU-CD": "Luba-Katanga",
  LV: "Latvian",
  "LV-LV": "Latvian",
  MAS: "Masai",
  "MAS-KE": "Masai",
  "MAS-TZ": "Masai",
  MER: "Meru",
  "MER-KE": "Meru",
  MFE: "Morisyen",
  "MFE-MU": "Morisyen",
  MG: "Malagasy",
  MGH: "Makhuwa-Meetto",
  "MGH-MZ": "Makhuwa-Meetto",
  MGO: "Meta",
  "MGO-CM": "Meta",
  "MG-MG": "Malagasy",
  MK: "Macedonian",
  "MK-MK": "Macedonian",
  ML: "Malayalam",
  "ML-IN": "Malayalam",
  MN: "Mongolian",
  "MN-MN": "Mongolian",
  MR: "Marathi",
  "MR-IN": "Marathi",
  MS: "Malay",
  "MS-ARAB": "Malay",
  "MS-ARAB-BN": "Malay",
  "MS-ARAB-MY": "Malay",
  "MS-BN": "Malay",
  "MS-MY": "Malay",
  "MS-SG": "Malay",
  MT: "Maltese",
  "MT-MT": "Maltese",
  MUA: "Mundang",
  "MUA-CM": "Mundang",
  MY: "Burmese",
  "MY-MM": "Burmese",
  MZN: "mzn",
  "MZN-IR": "mzn",
  NAQ: "Nama",
  "NAQ-NA": "Nama",
  NB: "Norwegian Bokmål",
  "NB-NO": "Norwegian Bokmål",
  "NB-SJ": "Norwegian Bokmål",
  ND: "North Ndebele",
  "ND-ZW": "North Ndebele",
  NE: "Nepali",
  "NE-IN": "Nepali",
  "NE-NP": "Nepali",
  NL: "Dutch",
  "NL-AW": "Dutch",
  "NL-BE": "Dutch",
  "NL-BQ": "Dutch",
  "NL-CW": "Dutch",
  "NL-NL": "Dutch",
  "NL-SR": "Dutch",
  "NL-SX": "Dutch",
  NMG: "Kwasio",
  "NMG-CM": "Kwasio",
  NN: "Norwegian Nynorsk",
  NNH: "Ngiemboon",
  "NNH-CM": "Ngiemboon",
  "NN-NO": "Norwegian Nynorsk",
  NUS: "Nuer",
  "NUS-SS": "Nuer",
  NYN: "Nyankole",
  "NYN-UG": "Nyankole",
  OM: "Oromo",
  "OM-ET": "Oromo",
  "OM-KE": "Oromo",
  OR: "Oriya",
  "OR-IN": "Oriya",
  OS: "Ossetic",
  "OS-GE": "Ossetic",
  "OS-RU": "Ossetic",
  PA: "Punjabi",
  "PA-ARAB": "Punjabi",
  "PA-ARAB-PK": "Punjabi",
  "PA-GURU": "Punjabi",
  "PA-GURU-IN": "Punjabi",
  PL: "Polish",
  "PL-PL": "Polish",
  PS: "Pashto",
  "PS-AF": "Pashto",
  PT: "Portuguese",
  "PT-AO": "Portuguese",
  "PT-BR": "Portuguese",
  "PT-CV": "Portuguese",
  "PT-GW": "Portuguese",
  "PT-MO": "Portuguese",
  "PT-MZ": "Portuguese",
  "PT-PT": "Portuguese",
  "PT-ST": "Portuguese",
  "PT-TL": "Portuguese",
  QU: "Quechua",
  "QU-BO": "Quechua",
  "QU-EC": "Quechua",
  "QU-PE": "Quechua",
  RM: "Romansh",
  "RM-CH": "Romansh",
  RN: "Rundi",
  "RN-BI": "Rundi",
  RO: "Romanian",
  ROF: "Rombo",
  "ROF-TZ": "Rombo",
  "RO-MD": "Romanian",
  "RO-RO": "Romanian",
  RU: "Russian",
  "RU-BY": "Russian",
  "RU-KG": "Russian",
  "RU-KZ": "Russian",
  "RU-MD": "Russian",
  "RU-RU": "Russian",
  "RU-UA": "Russian",
  RW: "Kinyarwanda",
  RWK: "Rwa",
  "RWK-TZ": "Rwa",
  "RW-RW": "Kinyarwanda",
  SAH: "Sakha",
  "SAH-RU": "Sakha",
  SAQ: "Samburu",
  "SAQ-KE": "Samburu",
  SBP: "Sangu",
  "SBP-TZ": "Sangu",
  SE: "Northern Sami",
  SEH: "Sena",
  "SEH-MZ": "Sena",
  SES: "Koyraboro Senni",
  "SES-ML": "Koyraboro Senni",
  "SE-FI": "Northern Sami",
  "SE-NO": "Northern Sami",
  "SE-SE": "Northern Sami",
  SG: "Sango",
  "SG-CF": "Sango",
  SHI: "Tachelhit",
  "SHI-LATN": "Tachelhit",
  "SHI-LATN-MA": "Tachelhit",
  "SHI-TFNG": "Tachelhit",
  "SHI-TFNG-MA": "Tachelhit",
  SI: "Sinhala",
  "SI-LK": "Sinhala",
  SK: "Slovak",
  "SK-SK": "Slovak",
  SL: "Slovenian",
  "SL-SI": "Slovenian",
  SMN: "Inari Sami",
  "SMN-FI": "Inari Sami",
  SN: "Shona",
  "SN-ZW": "Shona",
  SO: "Somali",
  "SO-DJ": "Somali",
  "SO-ET": "Somali",
  "SO-KE": "Somali",
  "SO-SO": "Somali",
  SQ: "Albanian",
  "SQ-AL": "Albanian",
  "SQ-MK": "Albanian",
  "SQ-XK": "Albanian",
  SR: "Serbian",
  "SR-CYRL": "Serbian",
  "SR-CYRL-BA": "Serbian",
  "SR-CYRL-ME": "Serbian",
  "SR-CYRL-RS": "Serbian",
  "SR-CYRL-XK": "Serbian",
  "SR-LATN": "Serbian",
  "SR-LATN-BA": "Serbian",
  "SR-LATN-ME": "Serbian",
  "SR-LATN-RS": "Serbian",
  "SR-LATN-XK": "Serbian",
  SV: "Swedish",
  "SV-AX": "Swedish",
  "SV-FI": "Swedish",
  "SV-SE": "Swedish",
  SW: "Swahili",
  "SW-CD": "Swahili",
  "SW-KE": "Swahili",
  "SW-TZ": "Swahili",
  "SW-UG": "Swahili",
  TA: "Tamil",
  "TA-IN": "Tamil",
  "TA-LK": "Tamil",
  "TA-MY": "Tamil",
  "TA-SG": "Tamil",
  TE: "Telugu",
  TEO: "Teso",
  "TEO-KE": "Teso",
  "TEO-UG": "Teso",
  "TE-IN": "Telugu",
  TG: "Tajik",
  "TG-TJ": "Tajik",
  TH: "Thai",
  "TH-TH": "Thai",
  TI: "Tigrinya",
  "TI-ER": "Tigrinya",
  "TI-ET": "Tigrinya",
  TK: "Turkmen",
  "TK-TM": "Turkmen",
  TO: "Tongan",
  "TO-TO": "Tongan",
  TR: "Turkish",
  "TR-CY": "Turkish",
  "TR-TR": "Turkish",
  TWQ: "Tasawaq",
  "TWQ-NE": "Tasawaq",
  TZM: "Central Atlas Tamazight",
  "TZM-MA": "Central Atlas Tamazight",
  UG: "Uighur",
  "UG-CN": "Uighur",
  UK: "Ukrainian",
  "UK-UA": "Ukrainian",
  UR: "Urdu",
  "UR-IN": "Urdu",
  "UR-PK": "Urdu",
  UZ: "Uzbek",
  "UZ-ARAB": "Uzbek",
  "UZ-ARAB-AF": "Uzbek",
  "UZ-CYRL": "Uzbek",
  "UZ-CYRL-UZ": "Uzbek",
  "UZ-LATN": "Uzbek",
  "UZ-LATN-UZ": "Uzbek",
  VAI: "Vai",
  "VAI-LATN": "Vai",
  "VAI-LATN-LR": "Vai",
  "VAI-VAII": "Vai",
  "VAI-VAII-LR": "Vai",
  VI: "Vietnamese",
  "VI-VN": "Vietnamese",
  VUN: "Vunjo",
  "VUN-TZ": "Vunjo",
  WAE: "Walser",
  "WAE-CH": "Walser",
  XOG: "Soga",
  "XOG-UG": "Soga",
  YAV: "Yangben",
  "YAV-CM": "Yangben",
  YI: "Yiddish",
  "YI-001": "Yiddish",
  YO: "Yoruba",
  "YO-BJ": "Yoruba",
  "YO-NG": "Yoruba",
  YUE: "Cantonese",
  "YUE-HK": "Cantonese",
  ZGH: "zgh",
  "ZGH-MA": "zgh",
  ZH: "Chinese",
  "ZH-HANS": "Chinese",
  "ZH-HANS-CN": "Chinese",
  "ZH-HANS-HK": "Chinese",
  "ZH-HANS-MO": "Chinese",
  "ZH-HANS-SG": "Chinese",
  "ZH-HANT": "Chinese",
  "ZH-HANT-HK": "Chinese",
  "ZH-HANT-MO": "Chinese",
  "ZH-HANT-TW": "Chinese",
  ZU: "Zulu",
  "ZU-ZA": "Zulu"
};
export default lang_code_to_language;