import GLOBAL from "./global";
import SDK from "./SDKLog";
import storage from "./storage";
var LOCATION = {};

LOCATION.locationHandler = function () {
  if (GLOBAL.hasLocationSet) {
    SDK.log("Required location information is already set by Application");
    return;
  }

  var geolocation_success = function geolocation_success(pos) {
    var _c = pos.coords;

    if (_c.latitude && _c.latitude && _c.accuracy && pos.timestamp) {
      SDK.log("Required information fetched using fetchLocation API hence remove geolocation listener");
      navigator.geolocation.clearWatch(locationWatch);
    }

    var locationInfo = {
      latitude: _c.latitude,
      longitude: _c.longitude,
      accuracy: _c.accuracy,
      timestamp: pos.timestamp
    };
    SDK.log("locationInfo using fetchLocation API => ".concat(JSON.stringify(locationInfo)));
    GLOBAL["lo"] = locationInfo["longitude"];
    GLOBAL["la"] = locationInfo["latitude"];
    GLOBAL["acc"] = locationInfo["accuracy"];
    GLOBAL["gts"] = locationInfo["timestamp"];
    GLOBAL.hasLocationSet = true;
    STORAGE.setItem("locationInfo", locationInfo);
  };

  var geolocation_error = function geolocation_error(error) {
    navigator.geolocation.clearWatch(locationWatch); //SDK.error(`Cannot access location. (${error.code}): ${error.message}`);
  };

  var options = {
    enableHighAccuracy: true,
    timeout: 1000 * 20,
    maximumAge: 0
  };
  navigator.geolocation.getCurrentPosition(geolocation_success, geolocation_error, options);
  var locationWatch = navigator.geolocation.watchPosition(geolocation_success, geolocation_error, options);
};

var STORAGE = new storage(); //const locationHandler = _locationHandler();

export default LOCATION;