import TRACKER from "../utils/tracker";
import storage from "../utils/storage";
import DESTROY from "./destroy";
import FCAP from "../module/business/fcap";
import SDK from "../utils/SDKLog";
import GLOBAL from "../utils/global";
import ERROR from "../utils/error";
import ERRORLOG from "../utils/errorLog";
import DOM from "../utils/dom";
import AD_OPPORTUNITY_LOG from "../utils/adOpportunityLog";
var CALLBACK = {};
var STORAGE = new storage();

CALLBACK.onSpotAdStart = function (caid, companion) {
  if (typeof JioAds.onSpotAdStart === "function") {
    JioAds.onSpotAdStart(caid, companion);
  }
};

CALLBACK.onSpotAdEnd = function (caid) {
  if (typeof JioAds.onSpotAdEnd === "function") {
    JioAds.onSpotAdEnd(caid);
  }
};

CALLBACK.onAdPrepared = function (options) {
  var placementId = options.placementId,
      adUxType = options.adUxType;

  if (typeof JioAds.onAdPrepared === "function") {
    JioAds.onAdPrepared(placementId, adUxType);
  }
};

CALLBACK.onAdMediaStart = function (options) {
  var placementId = options.placementId;

  if (typeof JioAds.onAdMediaStart === "function") {
    JioAds.onAdMediaStart(placementId, options);
  }
};

CALLBACK.onAdClicked = function (options) {
  var placementId = options.placementId,
      isAdDestroy = options.isAdDestroy,
      _options$isFcapDisabl = options.isFcapDisable,
      isFcapDisable = _options$isFcapDisabl === void 0 ? true : _options$isFcapDisabl,
      url = options.url,
      urld = options.urld;

  if (isFcapDisable) {
    FCAP.init({
      pId: placementId,
      type: "c"
    });
  }

  if (isAdDestroy) {
    DESTROY(placementId);
  }

  if (typeof JioAds.onAdClicked === "function") {
    JioAds.onAdClicked(placementId, url, urld);
  }
};

CALLBACK.onAdMediaEnd = function (options) {
  var placementId = options.placementId,
      reward = options.reward,
      _options$isFcapDisabl2 = options.isFcapDisable,
      isFcapDisable = _options$isFcapDisabl2 === void 0 ? true : _options$isFcapDisabl2;

  if (isFcapDisable) {
    FCAP.init({
      pId: placementId,
      type: "cv"
    });
  }

  if (typeof JioAds.onAdMediaEnd === "function") {
    JioAds.onAdMediaEnd(placementId, reward, options);
  }
};

CALLBACK.onAdSkippable = function (options) {
  var placementId = options.placementId;

  if (typeof JioAds.onAdSkippable === "function") {
    JioAds.onAdSkippable(placementId);
  }
};

CALLBACK.onAdRefresh = function (options) {
  var placementId = options.placementId;
  TRACKER.cleanDocument(placementId);
  DESTROY(placementId);

  if (typeof JioAds.onAdRefresh === "function") {
    JioAds.onAdRefresh(placementId);
  }
};

CALLBACK.onAdRender = function (options) {
  var placementId = options.placementId,
      url = options.url,
      adParams = options.adParams,
      _options$isFcapDisabl3 = options.isFcapDisable,
      isFcapDisable = _options$isFcapDisabl3 === void 0 ? true : _options$isFcapDisabl3;

  if (isFcapDisable) {
    FCAP.init({
      pId: placementId,
      type: "i"
    });
  }

  STORAGE.removeItem("".concat(placementId, "_Programmatic_Ad"));
  var INS = DOM.getInsData(placementId);
  var urlList = GLOBAL.AdManager[placementId].masterConfigManager.get({
    type: "AD_OP",
    value: INS.adspot,
    defaultValue: []
  });

  if (urlList.length) {
    AD_OPPORTUNITY_LOG(urlList, INS.adspot, true, true, true);
  }

  if (typeof JioAds.onAdRender === "function") {
    JioAds.onAdRender(placementId, url ? url[0] ? url[0] : "" : "", url ? url[1] ? url[1] : "" : "", adParams, url ? url[2] ? url[2] : "" : "");
  }
};

CALLBACK.onAdDuration = function (options) {
  var placementId = options.placementId,
      adTime = options.adTime,
      skip = options.skip;

  if (typeof JioAds.onAdDuration === "function") {
    JioAds.onAdDuration(placementId, adTime, skip);
  }
};

CALLBACK.onAdFailedToLoad = function (options) {
  var placementId = options.placementId,
      errorCode = options.errorCode,
      response = options.response,
      methodname = options.methodname;
  var INS = DOM.getInsData(placementId);

  if (GLOBAL.enableLogging) {
    var pos = Object.keys(ERROR).indexOf(errorCode);
    var deliveryMin = Object.keys(ERROR).indexOf("m104");
    var deliveryMax = Object.keys(ERROR).indexOf("m139");

    if (pos >= deliveryMin && pos <= deliveryMax) {// Dont log Delivery issues
    } else if (errorCode == "m108") {// Dont log client network failure issues
    } else {
      ERRORLOG(INS.adspot, errorCode, response, methodname);
    }
  }

  var urlList = GLOBAL.AdManager[placementId].masterConfigManager.get({
    type: "AD_OP",
    value: INS.adspot,
    defaultValue: []
  });

  if (urlList.length) {
    var pos = Object.keys(ERROR).indexOf(errorCode);
    var appMax = Object.keys(ERROR).indexOf("m109");
    var sdkMax = Object.keys(ERROR).indexOf("m141");
    var deliveryMax = Object.keys(ERROR).indexOf("m139");

    if (pos <= appMax) {
      AD_OPPORTUNITY_LOG(urlList, INS.adspot, false, false, false);
    } else if (pos <= sdkMax) {
      AD_OPPORTUNITY_LOG(urlList, INS.adspot, true, true, false);
    } else if (pos <= deliveryMax) {
      AD_OPPORTUNITY_LOG(urlList, INS.adspot, true, false, false);
    }
  }

  DESTROY(placementId);
  SDK.log("Destroyed " + placementId);

  if (typeof JioAds.onAdFailedToLoad === "function") {
    SDK.log("onAdFailedToLoadCalled " + placementId);
    JioAds.onAdFailedToLoad(placementId, options);
  }
};

CALLBACK.onAdClosed = function (options) {
  var placementId = options.placementId,
      isVideoCompleted = options.isVideoCompleted,
      reward = options.reward;
  TRACKER.cleanDocument(placementId);
  DESTROY(placementId);

  if (typeof JioAds.onAdClosed === "function") {
    JioAds.onAdClosed(placementId, isVideoCompleted, reward);
  }
};

CALLBACK.onMediaURLReady = function (options) {
  var streamURL = options.streamURL,
      mediaURL = options.mediaURL,
      clientSideTracking = options.clientSideTracking;

  if (typeof JioAds.onMediaURLReady === "function") {
    JioAds.onMediaURLReady(streamURL, mediaURL, clientSideTracking);
  }
};

CALLBACK.onMediaURLFailedToLoad = function (options) {
  var streamURL = options.streamURL,
      errorCode = options.errorCode,
      response = options.response,
      methodname = options.methodname;

  if (GLOBAL.enableLogging) {
    ERRORLOG(null, errorCode, response, methodname, streamURL);
  }

  DESTROY(streamURL);

  if (typeof JioAds.onAdFailedToLoad === "function") {
    SDK.log("onMediaURLFailedToLoad " + streamURL);
    JioAds.onMediaURLFailedToLoad(streamURL, options);
  }
};

CALLBACK.onAdChange = function (options) {
  var placementId = options.placementId,
      adInfo = options.adInfo,
      adCpInfo = options.adCpInfo;

  if (typeof JioAds.onAdChange === "function") {
    JioAds.onAdChange(placementId, adInfo, adCpInfo);
  }
};

CALLBACK.onAdsReceived = function (options) {
  var placementId = options.placementId,
      adResponse = options.adResponse,
      vastad = options.vastad;

  if (typeof JioAds.onAdsReceived === "function") {
    JioAds.onAdsReceived(placementId, adResponse);
  }
};

CALLBACK.isAdClick = function (options) {
  var placementId = options.placementId;

  if (typeof JioAds.isAdClick === "function") {
    JioAds.isAdClick(placementId);
  }
};

CALLBACK.onAdProgress = function (options) {
  var placementId = options.placementId,
      progress = options.progress,
      currentTime = options.currentTime;

  if (typeof JioAds.onAdProgress === "function") {
    JioAds.onAdProgress(placementId, progress, currentTime);
  }
};

CALLBACK.impressionFired = function (placementId, impUrl) {
  if (typeof JioAds.impressionFired === "function") {
    JioAds.impressionFired(placementId, impUrl);
  }
};

CALLBACK.viewImpFired = function (placementId, viewImp) {
  if (typeof JioAds.impressionFired === "function") {
    JioAds.viewImpFired(placementId, viewImp);
  }
};

CALLBACK.clickTrackerFired = function (placementId, clickTracker) {
  if (typeof JioAds.impressionFired === "function") {
    JioAds.clickTrackerFired(placementId, clickTracker);
  }
};

export default CALLBACK;