import storage from "./../../utils/storage";
var AdsequencingManager = {
  STORAGE: new storage(),
  setConfig: function setConfig(adspot, adspotType) {
    var adspotConfig = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var appConfig = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    var adspotId = "adseq_".concat(adspot);
    var adspotTypeId = "".concat(adspotId, "_").concat(adspotType);
    var adspotlocal, applocal;
    if (adspotConfig.hasOwnProperty("sh") && adspotConfig["sh"] == 0) adspotConfig = {};
    if (appConfig.hasOwnProperty("sh") && appConfig["sh"] == 0) appConfig = {};

    if (Object.keys(adspotConfig).length == 0 && Object.keys(appConfig).length == 0) {
      return;
    }

    adspotlocal = JSON.parse(localStorage.getItem(adspotId));
    applocal = JSON.parse(localStorage.getItem(adspotTypeId));

    if (adspotlocal !== null && Object.keys(adspotlocal).length !== 0) {
      return;
    } else if (adspotlocal !== null) {
      // adspot cycle inactive
      if (Object.keys(adspotConfig).length == 0) {
        this.STORAGE.removeItem(adspotId);
        this.STORAGE.setItem(adspotTypeId, appConfig);
      } else {
        this.STORAGE.removeItem(adspotTypeId);
        this.STORAGE.setItem(adspotId, adspotConfig);
      }

      return;
    }

    if (applocal !== null && Object.keys(applocal).length !== 0) {
      return;
    } else if (applocal !== null) {
      // adspotType cycle inactive
      if (Object.keys(adspotConfig).length == 0) {
        this.STORAGE.removeItem(adspotId);
        this.STORAGE.setItem(adspotTypeId, appConfig);
      } else {
        this.STORAGE.removeItem(adspotTypeId);
        this.STORAGE.setItem(adspotId, adspotConfig);
      }

      return;
    } //localstorage does not exsist


    if (Object.keys(adspotConfig).length == 0) {
      this.STORAGE.setItem(adspotTypeId, appConfig);
    } else {
      this.STORAGE.setItem(adspotId, adspotConfig);
    }
  },
  getConfig: function getConfig(adspot, adspotType) {
    var adspotKey = "adseq_".concat(adspot);
    var adspotTypeKey = "".concat(adspotKey, "_").concat(adspotType);
    var adspotConfig = this.STORAGE.getItem(adspotKey) || {};
    var appConfig = this.STORAGE.getItem(adspotTypeKey) || {};
    var isKeyNull = localStorage.getItem(adspotKey) === null && localStorage.getItem(adspotTypeKey) === null;
    var isConfigEmpty = Object.keys(appConfig).length == 0 && Object.keys(adspotConfig).length == 0;

    if (isKeyNull || isConfigEmpty) {
      return {
        isKeyNull: isKeyNull,
        isConfigEmpty: isConfigEmpty
      };
    }

    if (Object.keys(adspotConfig).length == 0) {
      return {
        key: adspotTypeKey,
        config: appConfig
      };
    } else {
      return {
        key: adspotKey,
        config: adspotConfig
      };
    }
  },
  getPermissionForAdRequest: function getPermissionForAdRequest(adspot, adspotType) {
    var seq = this.getConfig(adspot, adspotType);
    var isKeyNull = seq.hasOwnProperty("isKeyNull") && seq["isKeyNull"];
    var isConfigEmpty = seq.hasOwnProperty("isConfigEmpty") && seq["isConfigEmpty"];
    var isShowAdCounterKey = seq.hasOwnProperty("config") && seq["config"].hasOwnProperty("sh");
    var currentTime = new Date().getTime();

    if (isKeyNull || isConfigEmpty || isShowAdCounterKey) {
      return true;
    } else if (seq["config"]["sleep-time"] < currentTime) {
      this.STORAGE.removeItem(seq["key"]);
      return true;
    } else {
      if (seq["config"].hasOwnProperty("sk")) {
        seq["config"]["sk"] -= 1;

        if (seq["config"]["sk"] === 0) {
          seq["config"] = {};
        }

        this.STORAGE.setItem(seq["key"], seq["config"]);
      }

      return false;
    }
  },
  decrementShowAdCounter: function decrementShowAdCounter(adspot, adspotType) {
    var seq = this.getConfig(adspot, adspotType);
    var isKeyNull = seq.hasOwnProperty("isKeyNull") && seq["isKeyNull"];

    if (isKeyNull) {
      return true;
    } else if (seq["config"].hasOwnProperty("sh")) {
      seq["config"]["sh"] -= 1;

      if (seq["config"]["sh"] <= 0) {
        delete seq["config"]["sh"];
        var now = new Date();
        seq["config"]["sleep-time"] = now.setMinutes(now.getMinutes() + seq["config"]["sl"]);
      }

      this.STORAGE.setItem(seq["key"], seq["config"]);
    }
  }
};
export default AdsequencingManager;