function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import storage from "./../../utils/storage";
import SDK from "./../../utils/SDKLog";
import GLOBAL from "./../../utils/global";
import ERROR from "./../../utils/error";
import AdSelectionManager from "./adSelectionManager";
import getResponseHeader from "./../../module/application/reponseHeader";
import adFormatSelection from "./../../module/application/adFormatSelection";
import adNotFound from "./../../module/application/adNotFound";
var STORAGE = new storage();

var multiAdHandler = function multiAdHandler(options) {
  var _options$isProduction = options.isProductionData,
      isProductionData = _options$isProduction === void 0 ? true : _options$isProduction,
      _options$hybridMultiA = options.hybridMultiAdsEnable,
      hybridMultiAdsEnable = _options$hybridMultiA === void 0 ? false : _options$hybridMultiA,
      id = options.id,
      adSpotKey = options.adSpotKey,
      channelName = options.channelName,
      _options$campaignType = options.campaignType,
      campaignType = _options$campaignType === void 0 ? "cpd" : _options$campaignType,
      data = options.data;
  var adspotType = GLOBAL.ProductionAd.get({
    type: "MULTI-ADSPOT-TYPE",
    value: adSpotKey,
    defaultValue: false
  }) || GLOBAL.AdManager[id].masterConfigManager.get({
    type: "BACKUP-ADSPOT-TYPE",
    value: adSpotKey,
    defaultValue: false
  });

  if (GLOBAL.AdManager[id].hasOwnProperty("adbreakManager")) {
    if (adspotType == 5) {
      // Note : Adbreak duration is hard code value because there is no logic for ad selection if adbreak duartion is unavaliable
      if (GLOBAL.AdManager[id].isAdbreakDurationAvailable() == false) {
        var adbreakDuration = 30 * 60 * 1000;
        GLOBAL.AdManager[id]["adbreakManager"]["adbreakDuration"] = adbreakDuration;
        GLOBAL.AdManager[id].adBreakHandler(adbreakDuration);
      } else {
        GLOBAL.AdManager[id].adBreakHandler();
      }

      return;
    } else {
      delete GLOBAL.AdManager[id]["adbreakManager"];
    }
  } //Hybrid


  var campaignsList = isProductionData ? GLOBAL.ProductionAd.getCampaigns(adSpotKey, channelName) : hybridMultiAdsEnable ? GLOBAL.PrefetchAd.getCampaigns(adSpotKey) : GLOBAL.AdManager[id].masterConfigManager.get({
    type: "BACKUP-ADS",
    value: adSpotKey,
    defaultValue: {}
  });

  if (campaignType == "pgm") {
    var adParamsList = {};
    var programmaticCampaignAd = campaignsList[campaignType];
    var url = programmaticCampaignAd["ad_url"];
    var adParams = programmaticCampaignAd.hasOwnProperty("ad_params") && programmaticCampaignAd["ad_params"];
    var query = adParams.query.split("&");
    query.map(function (val) {
      var k = val.split("=");
      adParamsList[k[0]] = k[1];
    });
    adParamsList["method"] = adParams["method"];
    GLOBAL.AdManager[id].sendXHRRequest({
      url: url,
      adParamsList: adParamsList,
      onSuccess: function onSuccess(options) {
        var ad = options.ad,
            xhr = options.xhr,
            adSpotKey = options.adSpotKey,
            data = options.data;

        if (data["loadAd"]) {
          adFormatSelection({
            ad: ad,
            xhr: xhr,
            adSpotKey: adSpotKey,
            data: data
          });
        } else {
          var now = new Date();
          var expiryDate = now.setMinutes(now.getMinutes() + parseInt(programmaticCampaignAd["pgm_expiry"] || 0));
          var cacheData = {
            ad: ad,
            xhr: getResponseHeader({
              xhr: xhr
            }),
            data: _objectSpread({}, data),
            adSpotKey: adSpotKey,
            expiry: expiryDate
          };
          cacheData["data"]["loadAd"] = true;
          STORAGE.setItem("".concat(id, "_Programmatic_Ad"), cacheData);
          adFormatSelection({
            ad: ad,
            xhr: xhr,
            adSpotKey: adSpotKey,
            data: data
          });
        }

        return;
      },
      onError: function onError() {
        multiAdHandler({
          isProductionData: isProductionData,
          id: id,
          adSpotKey: adSpotKey,
          channelName: channelName,
          campaignType: "prm",
          data: data
        });
      }
    });
    return;
  }

  var adSelectionManager = null;
  var isCustomTargetingEnabled = GLOBAL.AdManager[id].isCustomTargetingEnabled();
  var customKeyValues = GLOBAL.AdManager[id].customKeyValues;

  if (isCustomTargetingEnabled) {
    adSelectionManager = new AdSelectionManager("WEIGHT", isCustomTargetingEnabled ? "KV-PARITY" : "", customKeyValues);
  } else {
    adSelectionManager = new AdSelectionManager();
  }

  adSelectionManager.getAd({
    isFcap: true,
    spotId: id,
    hybridMultiAdsEnable: hybridMultiAdsEnable,
    campaignsList: campaignsList[campaignType] || {},
    callback: function callback(options) {
      var ad = options.ad,
          modifiedData = options.modifiedData;

      if (Object.keys(ad).length == 0) {
        if (isProductionData == false) {
          var backUpAdList = null;

          if (hybridMultiAdsEnable) {
            backUpAdList = GLOBAL.PrefetchAd.getCampaigns(adSpotKey);
            GLOBAL.PrefetchAd.setCampaigns(adSpotKey, modifiedData, campaignType);
          } else {
            backUpAdList = GLOBAL.AdManager[id].MasterConfig.getAllData();

            if (backUpAdList["asi"][adSpotKey].hasOwnProperty("bkp")) {
              backUpAdList["asi"][adSpotKey]["bkp"]["cmps"][campaignType] = modifiedData;
              GLOBAL.AdManager[id].MasterConfig.set(backUpAdList);
            }
          }

          if (campaignType == "cpd") {
            multiAdHandler({
              isProductionData: false,
              id: id,
              adSpotKey: adSpotKey,
              channelName: channelName,
              campaignType: "dd",
              data: data
            });
          } else if (campaignType == "dd") {
            multiAdHandler({
              isProductionData: false,
              id: id,
              adSpotKey: adSpotKey,
              channelName: channelName,
              campaignType: campaignsList.hasOwnProperty("pgm") && Object.keys(campaignsList["pgm"]).length ? "pgm" : "prm",
              data: data
            });
          } else {
            SDK.error("".concat(id, ": No Fill"));
            adNotFound({
              status: "m104",
              errorCode: "m104",
              data: data
            });
            return;
          }
        } else {
          //Hybrid
          GLOBAL.ProductionAd.setCampaigns(adSpotKey, modifiedData, campaignType, channelName);

          if (campaignType == "cpd") {
            multiAdHandler({
              isProductionData: true,
              id: id,
              adSpotKey: adSpotKey,
              channelName: channelName,
              campaignType: "dd",
              data: data
            });
          } else if (campaignType == "dd") {
            multiAdHandler({
              isProductionData: true,
              id: id,
              adSpotKey: adSpotKey,
              channelName: channelName,
              campaignType: campaignsList.hasOwnProperty("pgm") && Object.keys(campaignsList["pgm"]).length ? "pgm" : "prm",
              data: data
            });
          } else {
            multiAdHandler({
              isProductionData: false,
              id: id,
              adSpotKey: adSpotKey,
              channelName: channelName,
              data: data
            });
          }
        }

        return;
      } else {
        if (isProductionData) {
          //Hybrid
          GLOBAL.ProductionAd.setCampaigns(adSpotKey, modifiedData, campaignType, channelName);
        } else {
          var _backUpAdList = null;

          if (hybridMultiAdsEnable) {
            _backUpAdList = GLOBAL.PrefetchAd.getCampaigns(adSpotKey);
            GLOBAL.PrefetchAd.setCampaigns(adSpotKey, modifiedData, campaignType);
          } else {
            _backUpAdList = GLOBAL.AdManager[id].MasterConfig.getAllData();

            if (_backUpAdList["asi"][adSpotKey].hasOwnProperty("bkp")) {
              _backUpAdList["asi"][adSpotKey]["bkp"]["cmps"][campaignType] = modifiedData;
              GLOBAL.AdManager[id].MasterConfig.set(_backUpAdList);
            }
          }
        }

        adFormatSelection(_objectSpread({
          ad: ad["ad"],
          data: _objectSpread(_objectSpread({}, data), {}, {
            ccbString: ad["ccbString"]
          })
        }, get_XHR_Header_From_Config(isProductionData, adSpotKey, channelName, campaignType, id, ad["campaignId"], ad["adId"])));
        return;
      }
    }
  });
};

var get_XHR_Header_From_Config = function get_XHR_Header_From_Config(isProduction, adSpotKey, channelName, campaignType, id, campaignId, adId) {
  var obj = {};
  var xhr = {};
  var data = isProduction ? GLOBAL.ProductionAd.getAdspotData(adSpotKey, channelName) : GLOBAL.AdManager[id].MasterConfig.getAllData();
  var adSpotData = data["asi"][adSpotKey];
  var campaignData = isProduction ? adSpotData["cmps"][campaignType][campaignId] : adSpotData["bkp"]["cmps"][campaignType][campaignId];
  var adData = campaignData["ads"][adId];
  xhr["campaignId"] = campaignId;
  xhr["adId"] = adId;
  var locationData = data["config"]["loc"];
  STORAGE.setItem("location_data", locationData);
  GLOBAL.locationData = STORAGE.getItem("location_data"); // console.log("location data-----" + locationData);

  xhr["allowCache"] = adData["config"]["mdc"];
  xhr["refreshTime"] = adData["config"]["adr"];
  xhr["adspotType"] = adSpotData["config"]["at"];
  xhr["delay"] = adSpotData["config"]["skd"] || adData["config"]["skd"];
  xhr["requestTimeout"] = adSpotData["config"]["adrt"];
  xhr["mediaTimeout"] = adSpotData["config"]["mdt"];
  xhr["jioCSL"] = data["config"]["csl"];
  xhr["orientation"] = adData["config"]["ao"];
  xhr["rewardIn"] = adSpotData["config"]["rwin"];
  xhr["mid"] = campaignData.hasOwnProperty("config") && campaignData["config"]["mid"];
  xhr["block"] = adSpotData["config"]["blk"];
  xhr["adSeq"] = adSpotData["config"]["adseq"];
  xhr["oia"] = adData["config"]["oia"];
  xhr["fmt"] = adData["config"]["fmt"];
  xhr["inl"] = adData["config"]["inl"];
  xhr["wh"] = adData["config"]["wh"];
  xhr["te"] = data["config"]["te"] || adSpotData["config"]["te"];
  xhr["loc"] = adSpotData["config"]["loc"];
  xhr["vce"] = adData["config"]["vce"];
  xhr["vim"] = adData["config"]["vim"];
  xhr["eac"] = data["config"]["eac"];
  xhr["tms"] = adSpotData["config"]["tms"];
  obj["xhr"] = xhr;
  return obj;
};

export default multiAdHandler;