import DOM from "../utils/dom";
import JioAdManager from "./../interface/jioadsmanager";
import GLOBAL from "./../utils/global";
import SDK from "./../utils/SDKLog";
import JioAdsUidGenerator from "./JioAdsUidGenerator";
import AdsequencingManager from "./../module/business/AdsequencingManager";
import DESTROY from "../interface/destroy.js";

function onINSTagInsert(event) {
  if (event.animationName == "insInjected") {
    var element = event.target;

    if (!GLOBAL.ifa) {
      var identifiers = null;

      if (GLOBAL.identifiers) {
        identifiers = GLOBAL.identifiers;
      }

      SDK.log("ifa fetching");
      var uidFetcher = new JioAdsUidGenerator({
        identifiers: identifiers
      });
    }

    renderAd(element.id);
    SDK.log(element.id);
  }
}

function renderAd(id) {
  SDK.log("SDK Render calling api");

  if (!id) {
    SDK.log("Placement Id and adspotKey required for rendering Ad");
    return;
  }

  if (!DOM.getElement(id)) {
    SDK.log("INS tag doesn't exist");
    return;
  }

  if (GLOBAL.AdManager.hasOwnProperty(id) && GLOBAL.adObject.hasOwnProperty(id) && GLOBAL.adObject[id]["isAdShown"]) {
    DESTROY(id);
  }

  var INS = DOM.getInsData(id);
  var isAdRequestAllowed = AdsequencingManager.getPermissionForAdRequest(INS.adspot);

  if (isAdRequestAllowed == false) {
    SDK.info("".concat(id, " : Ad Request Not Allowed"));
    var options = {
      placementId: id,
      errorCode: "m115",
      response: "Ad request not allowed",
      methodname: "loadAd"
    };

    if (typeof JioAds.onAdFailedToLoad === "function") {
      SDK.log("onAdFailedToLoadCalled " + id);
      JioAds.onAdFailedToLoad(id, options);
    }

    return;
  }

  SDK.log("JioManager load ads initiated");
  GLOBAL.AdManager[id] = new JioAdManager({
    id: id,
    loadAd: true
  });
  GLOBAL.AdManager[id].init();
  return;

  if (GLOBAL.AdManager.hasOwnProperty(id)) {
    SDK.log("JioManager show ads initiated", GLOBAL.AdManager[id]);
    GLOBAL.AdManager[id].loadAd();
  } else {
    SDK.log("JioManager load ads initiated");
    GLOBAL.AdManager[id] = new JioAdManager({
      id: id,
      loadAd: true
    });
    GLOBAL.AdManager[id].init();
  }
}

document.addEventListener("animationstart", onINSTagInsert, false); // firefox

document.addEventListener("MSAnimationStart", onINSTagInsert, false); // IE

document.addEventListener("webkitAnimationStart", onINSTagInsert, false); // Chrome

export default {
  renderAd: renderAd,
  onINSTagInsert: onINSTagInsert
};