import UTILS from "./../../utils/util";

function nativeAdResponseParser(options) {
  var ad = options.ad;

  try {
    // let dimension = UTILS.getAppOrientation() == 'l' ? '320x184' : '240x260';
    var dimension = "240x260";
    ad = UTILS.replaceMacros({
      str: ad,
      search: "\\[dimension\\]",
      macros: dimension
    });
    ad = JSON.parse(ad);

    if (ad.hasOwnProperty("result")) {
      return ad;
    } else {
      return {
        result: ad
      };
    }
  } catch (error) {
    return {};
  }
}

export default nativeAdResponseParser;