import GLOBAL from "./global";
import UTILS from "./util";

var AD_OPPORTUNITY_LOG = function AD_OPPORTUNITY_LOG(urlList, adspot, adReq, adDelivered, adRendered) {
  for (var i = 0; i < urlList.length; i++) {
    var Url = urlList[i];
    var finalurl = "";
    var macros = {
      asi: adspot,
      a: GLOBAL.ai,
      adreqs: adReq ? 1 : 0,
      adres: adDelivered ? 1 : 0,
      fs: adRendered ? 1 : 0
    };
    finalurl = UTILS.replaceMacrosValues(Url, macros, false);
    var xhe = new XMLHttpRequest();
    xhe.open("GET", finalurl, true);
    xhe.send();
  }
};

export default AD_OPPORTUNITY_LOG;