import "../styles/main.css";
import "./initservices/init";
import SDK from "./utils/SDKLog";
import DOM from "./utils/dom";
import GLOBAL from "./utils/global";
import DESTROY from "./interface/destroy";
import JioAdManager from "./interface/jioadsmanager";
import PreFetchManager from "./initservices/preFetchManager";
import AdsequencingManager from "./module/business/AdsequencingManager";
import CALLBACK from "./interface/callback";
import ERROR from "./utils/error";

function cacheAd(id) {
  if (!id) {
    SDK.error("placement Id required for intialize ad");
    return;
  }

  DESTROY(id);
  var INS = DOM.getInsData(id);
  var isAdRequestAllowed = AdsequencingManager.getPermissionForAdRequest(INS.adspot);

  if (isAdRequestAllowed == false) {
    SDK.error("".concat(id, " : Ad request not allowed"));
    CALLBACK.onAdFailedToLoad({
      placementId: this.placementId,
      errorCode: "m115",
      response: "Ad request not allowed",
      methodname: "cacheAd"
    });
    return;
  }

  SDK.log("cacheAd called with placement ID: " + id); //if (RewardManager.isRewardPointRemaining(id) == false) return;

  GLOBAL.AdManager[id] = new JioAdManager({
    id: id,
    loadAd: false
  });
  GLOBAL.AdManager[id].init();
}

function loadAd(id) {
  if (!id) {
    SDK.error("placement Id required for show ad");
    return;
  } //if (RewardManager.isRewardPointRemaining(id) == false) return;


  if (GLOBAL.AdManager.hasOwnProperty(id) && GLOBAL.adObject.hasOwnProperty(id) && GLOBAL.adObject[id]["isAdShown"]) {
    DESTROY(id);
  }

  var INS = DOM.getInsData(id);
  var isAdRequestAllowed = AdsequencingManager.getPermissionForAdRequest(INS.adspot);

  if (isAdRequestAllowed == false) {
    SDK.error("".concat(id, " : Ad request not allowed"));
    CALLBACK.onAdFailedToLoad({
      placementId: this.placementId,
      errorCode: "m115",
      response: "Ad request not allowed",
      methodname: "loadAd"
    });
    return;
  }

  SDK.log("loadAd called with placement ID: " + id);

  if (GLOBAL.AdManager.hasOwnProperty(id)) {
    SDK.log("Already cached Ad available for this placement ID: " + id);

    if (GLOBAL.adObject[id] && GLOBAL.adObject[id].isAdShown) {
      DESTROY(id);
    } else {
      GLOBAL.AdManager[id].show();
    }
  } else {
    SDK.log("Direct loadAd called for placement ID: " + id);
    GLOBAL.AdManager[id] = new JioAdManager({
      id: id,
      loadAd: true
    });
    GLOBAL.AdManager[id].init();
  }
}

function closeAd(id) {
  if (!id) {
    SDK.error("placement Id required for Close ad");
    return;
  }

  if (GLOBAL.AdManager.hasOwnProperty(id)) {
    SDK.log("closeAd called for placement ID: " + id);
    GLOBAL.AdManager[id].closeAd();
  } else {
    SDK.warn("closeAd called with unregistred placement ID: " + id);
  }
}

function closeAfter(id, closingTime) {
  if (!id) {
    SDK.error("placement Id required for Close ad");
    return;
  }

  if (GLOBAL.AdManager.hasOwnProperty(id)) {
    SDK.log("closeAfter called for placement ID: " + id);
    GLOBAL.AdManager[id].closeAfter(closingTime);
  } else {
    SDK.warn("closeAfter called with unregistred placement ID: " + id);
  }
}

function isAdSkippable(id) {
  if (!id) {
    SDK.error("placement Id required");
    return false;
  }

  if (GLOBAL.AdManager.hasOwnProperty(id)) {
    SDK.log("isAdSkippable called for placement ID: " + id);
    return GLOBAL.AdManager[id].isAdSkippable();
  } else {
    SDK.warn("isAdSkippable called with unregistred placement ID: " + id);
    return false;
  }
}

function setConfiguration(options) {
  if (Object.keys(options).length == 0) return;
  SDK.log("setConfiguration called with options: " + options);
  GLOBAL.setApplicationConfiguration(options);
  var appName = options.ai || false;

  if (appName) {
    GLOBAL.PreFetchManger[appName] = new PreFetchManager({
      appName: options.ai
    });
    GLOBAL.PreFetchManger[appName].init();
  }
}

function togglePlay(id) {
  var isPlay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  if (!id || isPlay == null) {
    SDK.error("placement Id required or toggle state is required");
    return false;
  }

  if (GLOBAL.AdManager.hasOwnProperty(id)) {
    if (isPlay) {
      GLOBAL.AdManager[id].firstTimePlay();
      GLOBAL.AdManager[id].resume();
    } else {
      GLOBAL.AdManager[id].pause();
    }
  }
}

function fireConversionApi(cid, level, options) {
  SDK.log("conversionRAte called with options: " + options);
  GLOBAL.setConversion(cid, level, options);
}

export { setConfiguration, closeAd, closeAfter, isAdSkippable, togglePlay, fireConversionApi };