import SDK from "./SDKLog";
import utils from "./util";
var DOM = {};

DOM.addClass = function (element, className) {
  if (element && typeof className === "string") {
    if (element.className) {
      if (element.className.indexOf(className) === -1) {
        element.className = (element.className + " " + className).replace(/\s\s+/g, " ");
      }
    } else {
      element.className = className;
    }
  }
};

DOM.removeClass = function (element, className) {
  if (element && typeof className === "string") {
    if (element.className.indexOf(className) > -1) {
      element.className = element.className.replace(className, "").replace(/\s\s+/g, " ");
    }
  }
};

var _getComputedStyle = function _getComputedStyle(element, style) {
  var propertyValue = "";

  if (element && typeof window.getComputedStyle === "function") {
    var cs = window.getComputedStyle(element, null);

    if (cs) {
      propertyValue = cs.getPropertyValue(style);
      propertyValue = propertyValue.toString().toLowerCase();
    }
  }

  return propertyValue;
};

var _getStyleAttributeData = function _getStyleAttributeData(element, style) {
  var styleAttributeData = _getComputedStyle(element, style) || 0;
  styleAttributeData = styleAttributeData.toString();

  if (styleAttributeData.indexOf("px") > -1) {
    styleAttributeData = styleAttributeData.replace("px", "");
  }

  return parseFloat(styleAttributeData);
};

DOM.setStyle = function (element, styleObject) {
  if (element && utils.isObject(styleObject)) {
    var keys = Object.keys(styleObject);

    for (var i = 0, len = keys.length; i < len; i++) {
      var currentKey = keys[i];
      element.style[currentKey] = styleObject[currentKey];
    }
  }
};

DOM.getWidth = function (element) {
  if (element) {
    if (utils.isNumber(element.offsetWidth) && element.offsetWidth !== 0) {
      return element.offsetWidth;
    } else {
      return _getStyleAttributeData(element, "width");
    }
  }

  return 0;
};

DOM.getHeight = function (element) {
  if (element) {
    if (utils.isNumber(element.offsetHeight) && element.offsetHeight !== 0) {
      return element.offsetHeight;
    } else {
      return _getStyleAttributeData(element, "height");
    }
  }

  return 0;
};

DOM.show = function (element) {
  if (element) {
    element.style.display = "block";
  }
};

DOM.hide = function (element) {
  if (element) {
    element.style.display = "none";
  }
};

DOM.removeElement = function (element) {
  if (element && element.parentNode) {
    try {
      element.parentNode.removeChild(element);
    } catch (e) {
      SDK.trace(e);
    }
  }
};

DOM.hasDOMParser = function () {
  if (typeof window.DOMParser !== "undefined") {
    return true;
  }

  return false;
};

DOM.getElement = function (element) {
  var ele = null;

  if (element) {
    try {
      ele = document.querySelector("#".concat(element));
      return ele;
    } catch (error) {
      SDK.log("Element Not Found");
      return null;
    }
  }

  return ele;
};

DOM.getInsData = function (id) {
  var ins_tag = this.getElement(id);
  var adspot = ins_tag.getAttribute("data-adspot-key");
  var refreshTime = ins_tag.getAttribute("data-refresh-after");
  return {
    adspot: adspot,
    refreshTime: refreshTime
  };
};

DOM.createDOMElement = function (tagName, tagProperties) {
  var el = document.createElement(tagName);

  for (prop in tagProperties) {
    el.setAttribute(prop, tagProperties[prop]);
  }

  return el;
};

DOM.getDOMDetails = function (options) {
  var id = options.id,
      dsKeys = options.dsKeys,
      subKeys = options.subKeys;
  var keys = [];
  var dsObject = {};
  if (!id) return {};
  var node = this.getElement(id);
  if (!dsKeys) return node.dataset;

  if (dsKeys.constructor.name !== "Array") {
    if (node.dataset.hasOwnProperty(dsKeys)) {
      dsObject[dsKeys] = node.dataset[dsKeys];
      return dsObject;
    } else return {};
  } else {
    keys = dsKeys.slice(0);
    return extractObjKV(node.dataset, keys, subKeys);
  }
};

function extractObjKV(obj, keys, sKeys) {
  var objKV = {};
  var key;
  var substitute = sKeys && keys.length === sKeys.length ? true : false;

  for (var k in keys) {
    if (obj.hasOwnProperty(keys[k])) {
      key = substitute ? sKeys[k] : keys[k];
      objKV[key] = obj[keys[k]];
    }
  }

  return objKV;
}

export default DOM;