import SDK from "./../../utils/SDKLog";
var responseHeaderList = {};
var responseHeaderKeyList = {
  allowCache: "X-Jio-MediaCaching",
  refreshTime: "X-Jio-AdRefresh",
  adspotType: "X-Jio-Adtype",
  delay: "X-Jio-SkipDuration",
  requestTimeout: "X-Jio-AdReqTimeout",
  mediaTimeout: "X-Jio-Mediatimeout",
  jioCSL: "X-Jio-CSL",
  orientation: "X-Jio-orientation",
  rewardIn: "X-Jio-RewardIn",
  mid: "X-Jio-MID",
  block: "X-Jio-Block",
  adSeq: "X-Jio-AdSeq",
  fcap: "X-Jio-Fcap",
  contentType: "Content-Type",
  wh: "X-Jio-WH",
  data: "X-Jio-Data",
  loc: "X-Jio-LOC",
  vce: "X-Jio-VCE"
};

function getHeaderByName(options) {
  var xhr = options.xhr,
      key = options.key;
  var value = "";

  try {
    value = xhr.getResponseHeader(key);
  } catch (e) {
    SDK.log("cant able to get header", e);
  }

  return value;
}

function getResponseHeader(options) {
  var xhr = options.xhr;
  var keyList = Object.keys(responseHeaderKeyList);

  for (var i = 0; i < keyList.length; i++) {
    var headerKey = keyList[i];
    responseHeaderList[headerKey] = getHeaderByName({
      xhr: xhr,
      key: responseHeaderKeyList[headerKey]
    });
  }

  if (responseHeaderList["data"]) {
    var parsedHeaders = function () {
      try {
        var _data$pod;

        var data = JSON.parse(responseHeaderList["data"]);
        return (data === null || data === void 0 ? void 0 : (_data$pod = data.pod) === null || _data$pod === void 0 ? void 0 : _data$pod.app_zone) || data;
      } catch (erro) {
        return {};
      }
    }();

    responseHeaderList["allowCache"] = parsedHeaders["mdc"];
    responseHeaderList["refreshTime"] = parsedHeaders["adr"];

    responseHeaderList["adspotType"] = function () {
      try {
        return parsedHeaders.hasOwnProperty("at") && parsedHeaders["at"] || parsedHeaders.hasOwnProperty("pod") && parsedHeaders["pod"]["app_zone"]["at"] || parsedHeaders.hasOwnProperty("fbk") && parsedHeaders["fbk"]["app_zone"]["at"];
      } catch (error) {
        SDK.error("Cant able to parse", error);
      }
    }();

    responseHeaderList["delay"] = parsedHeaders["skd"];
    responseHeaderList["requestTimeout"] = parsedHeaders["adrt"];
    responseHeaderList["mediaTimeout"] = parsedHeaders["mdt"];
    responseHeaderList["jioCSL"] = parsedHeaders["csl"];
    responseHeaderList["orientation"] = parsedHeaders["ao"];
    responseHeaderList["rewardIn"] = parsedHeaders["rwin"]; // responseHeaderList["mid"] = parsedHeaders["mid"];

    responseHeaderList["block"] = parsedHeaders["blk"];
    responseHeaderList["adSeq"] = parsedHeaders["adseq"]; // responseHeaderList["oia"] = parsedHeaders["oia"];
    // responseHeaderList["fmt"] = parsedHeaders["fmt"];
    // responseHeaderList["inl"] = parsedHeaders["inl"];
    // responseHeaderList["te"] = parsedHeaders["te"];
    // responseHeaderList["loc"] = parsedHeaders["loc"];
    // responseHeaderList["vce"] = parsedHeaders["vce"];

    Object.assign(responseHeaderList, parsedHeaders);
  }

  return responseHeaderList;
}

export default getResponseHeader;