var ERROR = {
  // Device or App issues
  m105: "Internet is not available",
  m108: "Network Error",
  m109: "Mandatory params missing",
  // SDK issues
  m110: "Ad rendition error",
  m111: "Parsing error",
  m112: "cacheAd failed",
  m113: "Error while downloading ad",
  m120: "Error while fetching advid or uid",
  m121: "AdPod Timeout error",
  m123: "Player preparation failed",
  m136: "Invalid Ad Request Parameters",
  m101: "Error while fetching advid",
  m102: "Error while fetching UID",
  m103: "Error fetching vast meta data",
  m138: "Error during CTA click",
  m140: "Not able to render backup ad",
  m141: "JS Exception during targeting",
  m143: "LoadAd failed",
  // Delivery Issues
  m104: "No Fill",
  m106: "Timeout while fetching ad",
  m107: "Wrong UX type",
  m114: "Ad spot doesn't exist",
  m115: "AD request not allowed",
  m116: "Adspot is not linked to application",
  m117: "Request from Invalid/restricted origin",
  m118: "UA is Invalid/doesn't exist",
  m119: "OS is Invalid/doesn't exist",
  m122: "Restricted api, please contact your account manager for more details",
  m124: "User daily impression limit reached",
  m125: "user lifetime impression limit reached",
  m126: "user click daily limit reached",
  m127: "user click lifetime limit reached",
  m128: "user completed view daily limit reached",
  m129: "user completed view lifetime limit reached",
  m130: "user minute wise impression limit reached",
  m131: "user hourly impression limit reached",
  m132: "user click minute wise limit reached",
  m133: "user click hourly limit reached",
  m134: "user completed view minute wise limit reached",
  m135: "user completed view hourly limit reached",
  m137: "Master Config Download failed",
  m139: "Mediation Error",
  //SSAI errors
  s101: "Stream view url cannot be empty",
  //s102: "Headers cannot be null for VOD stream type",
  //s103: "Headers markers cannot be empty",
  //s104: "Headers content URL cannot be empty",
  s105: "Malformed URL Observed",
  //s106: "Socket Timeout while network call",
  //s107: "Unable to connect while network call",
  //s108: "SSLPeerUnverifiedException while network call",
  s109: "IOException while network call",
  //s110: "Unknown exception while network call",
  //s111: "Wrapper ad failed",
  s112: "Vast Parsing Error",
  s113: "Manifest Parsing Error",
  s114: "Cohort Group ID Failure"
};
export default ERROR;