import HTTPRequest from "./http-request";
var SDK = {};
SDK.LOG_LEVEL = {
  debug: 1,
  log: 2,
  info: 3,
  trace: 4,
  warn: 5,
  error: 6
};
SDK.consoleStyleOne = "color: white; background-color: #095aac; padding:1px 3px; border-radius: 3px; margin-right: 7px";
SDK.logCache = [];
SDK.logLevel = SDK.LOG_LEVEL.debug;
SDK.logCacheEnable = false;
SDK.maxCacheSize = 1024;
SDK.loggingURL = null;

SDK.setLogLevel = function (newLogLevel) {
  SDK.logLevel = newLogLevel;
};

SDK.debug = function (data) {
  if (SDK.logLevel <= SDK.LOG_LEVEL.debug) {
    SDK.cacheLog(data);
    window.console.debug("%cWebsdk log%c" + data, SDK.consoleStyleOne, "");
  }
};

SDK.log = function (data) {
  if (SDK.logLevel <= SDK.LOG_LEVEL.log) {
    SDK.cacheLog(data);
    window.console.log("%cWebsdk log%c" + data, SDK.consoleStyleOne, "");
  }
};

SDK.info = function (data) {
  if (SDK.logLevel <= SDK.LOG_LEVEL.info) {
    SDK.cacheLog(data);
    window.console.info("%cWebsdk log%c" + data, SDK.consoleStyleOne, "");
  }
};

SDK.trace = function (data) {
  if (SDK.logLevel <= SDK.LOG_LEVEL.trace) {
    SDK.cacheLog(data);
    window.console.trace("%cWebsdk log%c" + data, SDK.consoleStyleOne, "");
  }
};

SDK.warn = function (data) {
  if (SDK.logLevel <= SDK.LOG_LEVEL.warn) {
    SDK.cacheLog(data);
    window.console.warn("%cWebsdk log%c" + data, SDK.consoleStyleOne, "");
  }
};

SDK.error = function (data) {
  if (SDK.logLevel <= SDK.LOG_LEVEL.error) {
    SDK.cacheLog(data);
    window.console.error("%cWebsdk log%c" + data, SDK.consoleStyleOne, "");
  }
};

SDK.setLogCacheing = function (enable, maxCacheSize, loggingURL) {
  SDK.logCacheEnable = enable;
  SDK.maxCacheSize = maxCacheSize;
  SDK.loggingURL = loggingURL;
};

SDK.cacheLog = function (args) {
  if (!SDK.logCacheEnable) {
    return;
  }

  SDK.logCache.push(args);
  var pushLogData = SDK.logCache;

  if (SDK.logCache.length >= SDK.maxCacheSize) {
    var options = {
      method: "POST",
      timeout: 5000,
      pushLogData: pushLogData
    };
    new HTTPRequest().ajax(SDK.loggingURL, options, function (success, timestamp) {
      var resp = success.response;
      if (resp && resp.hasOwnProperty("success") && resp.success === false) return;
      SDK.logCache = [];
    }, function (error, timestamp) {
      SDK.logCache = [];
    });
  }
};

export default SDK;