var EventList = {
  hot: ["APPLAUNCHEVENT", "DEEPLINKLAUNCHEVENT", "ADDTOCART", "REMOVEFROMCART", "ADDTOWISHLIST", "REMOVEFROMWISHLIST", "PURCHASECOMPLETED"],
  cold: ["CARTVIEWEVENT", "PAGEVIEWEVENT", "PRODUCTLISTVIEWEVENT", "PRODUCTVIEWEVENT", "SEARCHVIEWEVENT", "LOCATIONEVENT", "CUSTOMEVENT"],
  all: {
    1: "APPLAUNCHEVENT",
    2: "DEEPLINKLAUNCHEVENT",
    3: "ADDTOCART",
    4: "REMOVEFROMCART",
    5: "ADDTOWISHLIST",
    6: "REMOVEFROMWISHLIST",
    7: "PURCHASECOMPLETED",
    8: "CARTVIEWEVENT",
    9: "PAGEVIEWEVENT",
    10: "PRODUCTLISTVIEWEVENT",
    11: "PRODUCTVIEWEVENT",
    12: "SEARCHVIEWEVENT",
    13: "LOCATIONEVENT",
    14: "CUSTOMEVENT",
    15: "DEVICE INFORMATION",
    16: "UserDetails Event",
    17: "Product Cancelled Event",
    18: "Product Returned Event"
  }
};
export default EventList;