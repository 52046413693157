function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

import SDK from "./SDKLog";
import DOM from "./dom";
import GLOBAL from "./global";
import EVENTLIST from "./eventList";
var UTILS = {};

UTILS.isNumber = function (n) {
  if (typeof n !== "undefined" && typeof n === "number" && Number.isFinite(n)) {
    return true;
  }

  return false;
};

UTILS.isObject = function (obj) {
  if (typeof obj !== "undefined" && obj !== null && _typeof(obj) === "object") {
    return true;
  }

  return false;
};

UTILS.isEmpty = function (argument) {
  return true; // write function body
};

UTILS.getRandomInteger = function (min, max) {
  if (!min || !max) {
    SDK.log("insufficient prameter for getRandomInteger");
    return false;
  }

  return Math.min(max, Math.floor(Math.random() * (max - min + 1) + min));
};

UTILS.ellipsisText = function (str, len) {
  if (str.length > len) return "".concat(str.substring(0, len - 3), " ...");else return str;
};

UTILS.filtered = function (options) {
  var raw = options.raw,
      keyList = options.keyList;

  try {
    return Object.keys(raw).filter(function (key) {
      return keyList.includes(key);
    }).reduce(function (obj, key) {
      if (raw[key]) {
        obj[key] = raw[key];
      }

      return obj;
    }, {});
  } catch (error) {
    SDK.log("cant able to filtered");
    return {};
  }
};

UTILS.clickControlEnabled = function (options) {
  /*let { pId } = options;
  let enabled = false;
    //let storageId = (!persistantCall) ? '' : 'local';
    var xBodyId = '';
    if(!pId)
        pId = vmaxHelper.getAdSlotId();
    xBodyId = pId + '_xBodyCC';
    if(getStorage(xBodyId) == 1)
        enabled = true;
    return enabled;*/
  return true;
};

UTILS.replaceMacros = function (options) {
  var str = options.str,
      search = options.search,
      macros = options.macros;
  var regex = new RegExp(search, "gi");
  return str.replace(regex, macros || search);
};

UTILS.getChannelName = function (adSpotKey) {
  var placementId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var name = "";

  if (GLOBAL.hasOwnProperty("globalMetaData")) {
    Object.keys(GLOBAL.globalMetaData).forEach(function (key) {
      if (key == "loa" || key == "lang") {
        name = GLOBAL.globalMetaData[key];
      }
    });
  }

  if (placementId) {
    var insTagParams = DOM.getDOMDetails({
      id: placementId,
      dsKeys: ["admetadata"]
    });

    if (insTagParams && insTagParams["admetadata"]) {
      var metadata = JSON.parse(insTagParams["admetadata"]);
      Object.keys(metadata).forEach(function (key) {
        if (key == "lang") {
          name = metadata[key];
        }
      });

      if (!name.length) {
        Object.keys(metadata).forEach(function (key) {
          if (key == "loa") {
            name = metadata[key];
          }
        });
      }
    }
  }

  var channel = name.length ? "".concat(adSpotKey, "_").concat(name.toString().toUpperCase()) : adSpotKey;
  return channel;
};

UTILS.generateMacrosList = function (macros, placementId) {
  var isClick = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  Object.assign(macros, GLOBAL);

  if (GLOBAL.hasOwnProperty("globalMetaData")) {
    Object.keys(GLOBAL.globalMetaData).forEach(function (key) {
      macros["md_".concat(key)] = GLOBAL.globalMetaData[key];
    });
  }

  var insTagParams = DOM.getDOMDetails({
    id: placementId,
    dsKeys: ["admetadata"]
  });

  if (insTagParams) {
    Object.keys(insTagParams).forEach(function (key) {
      macros["md_".concat(key)] = insTagParams[key];
    });
  }

  if (insTagParams && insTagParams["admetadata"]) {
    var metadata = JSON.parse(insTagParams["admetadata"]);
    Object.keys(metadata).forEach(function (key) {
      macros["md_".concat(key)] = metadata[key];
    });
  }

  macros["cmd"] = "";
  Object.keys(macros).forEach(function (key) {
    if (key.startsWith("md_") && key != "md_admetadata") {
      if (macros["cmd"].length) macros["cmd"] += isClick ? "__" : "&";
      macros["cmd"] += "".concat(key, "=").concat(macros[key]);
    }
  });
};

UTILS.generateGlobalMacrosList = function (macros) {
  var isClick = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  Object.assign(macros, GLOBAL);

  if (GLOBAL.hasOwnProperty("globalMetaData")) {
    Object.keys(GLOBAL.globalMetaData).forEach(function (key) {
      macros["md_".concat(key)] = GLOBAL.globalMetaData[key];
    });
  }

  macros["cmd"] = "";
  Object.keys(macros).forEach(function (key) {
    if (key.startsWith("md_") && key != "md_admetadata") {
      if (macros["cmd"].length) macros["cmd"] += isClick ? "__" : "&";
      macros["cmd"] += "".concat(key, "=").concat(macros[key]);
    }
  });
};

UTILS.isBrandPageURL = function (url) {
  var queryparams = url.split("?")[1];

  if (queryparams) {
    var params = queryparams.split("&");
    var pair = null,
        data = {};
    params.forEach(function (d) {
      pair = d.split("=");
      data[pair[0]] = pair[1];
    });
    if (data["jiopopup"]) return true;
  }

  return false;
};

UTILS.renderBrandPage = function (url, macros) {
  var modeldiv = document.createElement("div");
  modeldiv.setAttribute("id", "modelframe");
  modeldiv.className = "modal modal-brand";
  var framediv = document.createElement("div");
  framediv.className = "modal-content";
  var frame = document.createElement("iframe");
  frame.setAttribute("id", "rndIframeId10000");
  frame.className = "frame-content";
  if (GLOBAL.platform == "de") frame.classList.add("desktop");
  frame.src = "https://mercury.akamaized.net/jioads/websdk/brand/cad.html";
  var clickdiv = document.createElement("div");
  clickdiv.className = "modal-clickhandler";
  framediv.appendChild(frame);
  modeldiv.appendChild(framediv);
  modeldiv.appendChild(clickdiv);
  document.body.appendChild(modeldiv);

  window.onclick = function (event) {
    if (event.target == clickdiv) {
      modeldiv.style.display = "none";
      modeldiv.parentNode.removeChild(modeldiv);
    }

    if (event.target.id == "rndIframeId10000") {
      modeldiv.style.display = "none";
      modeldiv.parentNode.removeChild(modeldiv);
    }
  };
};

UTILS.replaceMacrosValues = function (url, macros) {
  var click = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var replacedMacrosUrl = url;

  for (var key in macros) {
    var value = macros[key]; // this will match [${key}] and %%${key}%% and replace it

    if (key == "cmd" && value) {
      var cmdstring = value.toString();

      if (click) {
        replacedMacrosUrl = replacedMacrosUrl.replace(/\[cmd\]|\{cmd\}/gi, cmdstring.replace(/&/g, "__"));
      } else {
        replacedMacrosUrl = replacedMacrosUrl.replace(/\[cmd\]|\{cmd\}/gi, cmdstring);
      }
    }

    replacedMacrosUrl = replacedMacrosUrl.replace(/%5B/g, "[").replace(/%5D/g, "]").replace(new RegExp("(?:\\[|%%)(".concat(key, ")(?:\\]|%%)"), "g"), value).replace(/\[trq\]|\{trq\}/gi, encodeURI(new Date().getTime()));
  }

  return replacedMacrosUrl;
};

UTILS.generateCCB = function (placementId) {
  return UTILS.crc32((Date.now() || new Date().getTime()) + ":" + GLOBAL.appName + ":" + placementId + ":" + Math.random(10) * 100);
};

UTILS.crc32 = function (str) {
  for (var a, o = [], c = 0; c < 256; c++) {
    a = c;

    for (var f = 0; f < 8; f++) {
      a = 1 & a ? 3988292384 ^ a >>> 1 : a >>> 1;
    }

    o[c] = a;
  }

  for (var n = -1, t = 0; t < str.length; t++) {
    n = n >>> 8 ^ o[255 & (n ^ str.charCodeAt(t))];
  }

  return (-1 ^ n) >>> 0;
};

UTILS.jsonParser = function (str) {
  try {
    if (typeof str == "string") {
      return JSON.parse(str);
    } else {
      return str;
    }
  } catch (error) {
    return {};
  }
};

UTILS.pick = function (object, props) {
  object = Object(object);
  return props.reduce(function (result, key) {
    if (key in object) {
      result[key] = object[key];
    }

    return result;
  }, {});
};

UTILS.overlayInviewDetect = function (selector) {
  if (GLOBAL.overlayInview) {
    //SDK.log("inview_OLI");
    var rect = selector.getBoundingClientRect();
    var x = (rect.left + rect.right) / 2;
    var y = (rect.top + rect.bottom) / 2;
    var infocus = document.elementFromPoint(x, y);

    if (infocus && (infocus == selector || infocus.contains(selector) || selector.contains(infocus) || infocus.offsetParent == selector.offsetParent || (infocus.offsetParent && infocus.offsetParent.parentElement ? infocus.offsetParent.parentElement.contains(selector) : false) || (infocus.offsetParent ? infocus.offsetParent.contains(selector) : false) || (selector.offsetParent ? selector.offsetParent.contains(infocus) : false))) {
      SDK.log("element view not overlayed by any other element");
      return true;
    } else {
      SDK.log("element view overlayed by any other element");
      return false;
    }
  } else return true;
};

UTILS.isEventExist = function (eventType) {
  try {
    if (EVENTLIST.all.hasOwnProperty(eventType)) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    SDK.error("something went worng, ".concat(error));
    return false;
  }
};

UTILS.isContainsAll = function () {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var arr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var productList = obj.hasOwnProperty("productDetails") && obj["productDetails"].hasOwnProperty("productList") && Object.keys(obj["productDetails"]["productList"]).length && obj["productDetails"]["productList"] || [];

  for (var i = 0; i < productList.length; i++) {
    var productListKey = ["id", "vertical", "price", "segment", "brickname", "clickId", "sku"];

    for (var _i = 0, _productListKey = productListKey; _i < _productListKey.length; _i++) {
      var productKey = _productListKey[_i];
      UTILS.ensureExists(productList[i], productKey);
    }
  }

  var customerDetails = obj.hasOwnProperty("customerDetails") && obj["customerDetails"] || [];

  for (var _i2 = 0; _i2 < customerDetails.length; _i2++) {
    var customerDetailsListKey = ["type"];

    for (var _i3 = 0, _customerDetailsListK = customerDetailsListKey; _i3 < _customerDetailsListK.length; _i3++) {
      var customerDetailsKey = _customerDetailsListK[_i3];
      UTILS.ensureExists(customerDetails[_i2], customerDetailsKey);
    }
  }

  var _iterator = _createForOfIteratorHelper(arr),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var key = _step.value;
      UTILS.ensureExists(obj, key);
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return true;
};

UTILS.ensureExists = function () {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  var value = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var doNotReplace = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

  if (typeof path === "number") {
    path = [path];
  }

  if (!path || path.length === 0) {
    return obj;
  }

  if (typeof path === "string") {
    return UTILS.ensureExists(obj, path.split(".").map(UTILS.getKey), value ? value : "", false);
  }

  var currentPath = path[0];
  var currentValue = UTILS.getShallowProperty(obj, currentPath);

  if (path.length === 1) {
    if ((currentValue === void 0 || currentValue == null) && !doNotReplace) {
      obj[currentPath] = value;
    }

    return currentValue;
  }

  if (currentValue === void 0) {
    //check if we assume an array
    if (typeof path[1] === "number") {
      obj[currentPath] = [];
    } else {
      obj[currentPath] = {};
    }
  }

  return UTILS.ensureExists(obj[currentPath], path.slice(1), value, doNotReplace);
};

UTILS.getShallowProperty = function (obj, prop) {
  if (UTILS.hasShallowProperty(obj, prop)) {
    return obj[prop];
  }
};

UTILS.hasShallowProperty = function (obj, prop) {
  return typeof prop === "number" && Array.isArray(obj) || UTILS.hasOwnProperty(obj, prop);
};

UTILS.getKey = function (key) {
  var intKey = parseInt(key);

  if (intKey.toString() === key) {
    return intKey;
  }

  return key;
};

UTILS.hasOwnProperty = function (obj, prop) {
  if (obj == null) {
    return false;
  } //to handle objects with null prototypes (too edge case?)


  return Object.prototype.hasOwnProperty.call(obj, prop);
};

UTILS.getTMS = function () {
  var date = new Date();
  var tms = date.toISOString(); // var tz = date.getTimezoneOffset();
  // var sign = tz > 0 ? "-" : "+";
  // var hours =
  //   Math.floor(Math.abs(tz) / 60) < 10
  //     ? "0" + Math.floor(Math.abs(tz) / 60)
  //     : Math.floor(Math.abs(tz) / 60);
  // var minutes =
  //   Math.abs(tz) % 60 < 10 ? "0" + (Math.abs(tz) % 60) : Math.abs(tz) % 60;
  // var tzOffset = sign + hours + minutes;

  return tms.replace(/.{0,1}$/, "+0000");
};

UTILS.isfirstLaunchExist = function () {
  var key = localStorage.getItem("firstLaunch");

  if (key == null) {
    localStorage.setItem("firstLaunch", 0);
    return 1;
  }

  return 0;
};

export default UTILS;