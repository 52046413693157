import SDK from "../utils/SDKLog";
import GLOBAL from "../utils/global";
import UTILS from "../utils/util";
import HTTPRequest from "../utils/http-request";
var COHORT_ID = {};

COHORT_ID.isDefaultCohortID = function (cgi) {
  var defaultCGI = "defaultCGI";

  if (cgi == defaultCGI) {
    return true;
  }

  return false;
};

COHORT_ID.makeCohortBeaconHit = function (cohortUEURL) {
  if (!cohortUEURL || cohortUEURL.length < 1) return;

  if (GLOBAL.locationData) {
    GLOBAL.md_cn = GLOBAL.locationData.cn ? GLOBAL.locationData.cn : "";
    GLOBAL.md_ct = GLOBAL.locationData.ct ? GLOBAL.locationData.ct : "";
    GLOBAL.md_pn = GLOBAL.locationData.pn ? GLOBAL.locationData.pn : "";
    GLOBAL.md_st = GLOBAL.locationData.st ? GLOBAL.locationData.st : "";
  }

  var macros = {
    ts: new Date().getTime(),
    uid: GLOBAL.uid,
    ifa: GLOBAL.ifa
  };
  UTILS.generateGlobalMacrosList(macros);

  for (var i = 0; i < cohortUEURL.length; i++) {
    var beaconurl = "".concat(cohortUEURL[i], "?ts=").concat(new Date().getTime(), "&uid=").concat(macros.uid ? macros.uid : "", "&ifa=").concat(macros.ifa ? macros.ifa : "", "&st=").concat(macros.md_st ? macros.md_st : "", "&ct=").concat(macros.md_ct ? macros.md_ct : "", "&g=").concat(macros.md_g ? macros.md_g : "", "&ag=").concat(macros.md_ag ? macros.md_ag : "", "&dp=").concat(macros.md_dp ? macros.md_dp : "", "&msc=");

    var cbCohortSucc = function cbCohortSucc(response) {
      SDK.debug("Successfull hit becon for updating user info");
    };

    var cbCohortErr = function cbCohortErr(data) {
      SDK.error("Unable to hit becon for updating user info");
    };

    var lRequest = new HTTPRequest();
    lRequest.ajax(beaconurl, {
      method: "GET",
      timeout: GLOBAL.adRequestTimeout,
      checkJSON: false
    }, cbCohortSucc, cbCohortErr);
  }
};

COHORT_ID.RequestForCohortID = function (cohortURL, cohortUEURL, newVR) {
  return new Promise(function (resolve, reject) {
    var bpid = GLOBAL.uid;
    var ifa = GLOBAL.ifa;

    if (bpid || ifa) {
      if (cohortURL) {
        var _loop = function _loop() {
          indexURL = cohortURL[i];
          if (bpid) indexURL = "".concat(indexURL, "?b=").concat(bpid);else indexURL = "".concat(indexURL, "?i=").concat(ifa);
          var xhr = new XMLHttpRequest({
            mozSystem: true
          });

          xhr.onload = function () {
            if (xhr.status != 200) {
              SDK.log("Network Error = " + xhr.status + " statusText = " + xhr.statusText);
              GLOBAL.cgi = "defaultCGI";
              COHORT_ID.makeCohortBeaconHit(cohortUEURL);
              resolve();
            } else {
              var response = JSON.parse(xhr.responseText);

              if (response.gp_id) {
                localStorage.setItem("cgi", response.gp_id);
                localStorage.setItem("cgi_vr", newVR);
                var now = new Date();
                localStorage.setItem("cgi_exp", now.setHours(now.getHours() + 24));
                GLOBAL.cgi = response.gp_id;

                if (COHORT_ID.isDefaultCohortID(response.gp_id)) {
                  COHORT_ID.makeCohortBeaconHit(cohortUEURL);
                }

                resolve();
              }
            }
          };

          xhr.onerror = function () {
            SDK.warn("Unable to Fetch Cohort ID. Using Default One");
            GLOBAL.cgi = "defaultCGI";
            COHORT_ID.makeCohortBeaconHit(cohortUEURL);
            resolve();
          };

          xhr.open("GET", indexURL, false);
          xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
          xhr.send();
        };

        for (var i = 0; i < cohortURL.length; i++) {
          var indexURL;

          _loop();
        }

        resolve();
      } else {
        GLOBAL.cgi = "defaultCGI";
        COHORT_ID.makeCohortBeaconHit(cohortUEURL);
        resolve();
      }
    }
  });
};

export default COHORT_ID;