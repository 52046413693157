function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var createEndCard = function createEndCard(endCard) {
  var mimeType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "video";
  var imageFlag = arguments.length > 2 ? arguments[2] : undefined;

  if (!endCard) {
    return null;
  }

  if (_typeof(endCard) != "object") {
    return endCard;
  } else {
    return endCard.mainimage || endCard.mediumimage;
  }
};

export default createEndCard;