function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

import LOCATION from "./location";
import storageManager from "./../module/multiAd/storageManager";
import ConnectionType from "./connectionType";
import MasterConfigManager from "./../module/multiAd/masterConfigManager";
import SDK from "./SDKLog";
import JioAdsUidGenerator from "./../initservices/JioAdsUidGenerator";
import storageManagerRe from "./../interface/storageManager";
import INIT from "./../initservices/initUid";
import storage from "./storage";
var GLOBAL = {
  sdkVer: "1.0.0",
  appName: "",
  appId: "com.jio.web",
  appSdkVer: "1.0",
  language: navigator.language,
  country: "IN",
  maxEvents: 10,
  maxRedirects: 5,
  maxRetries: 5,
  sdkRetries: 0,
  eventExpiry: 10080,
  maxStorageTime: 1440,
  gzipEnablePayloadSize: 1300,
  level_1_timeout: 9,
  level_2_timeout: 216,
  appVersion: "",
  version: "1.15.19",
  endpoint: "mercury",
  platform: "si",
  reqType: "prod",
  // 'live'
  tz: new Date().toString(),
  clkSelf: false,
  lo: 0,
  la: 0,
  acc: 0,
  gts: 0,
  hasLocationSet: false,
  isTouch: touchPhone(),
  ao: "p",
  tpl: 1,
  // ua:
  //   navigator.userAgent.includes("Chrome") ||
  //   navigator.userAgent.includes("Safari")
  //     ? "Mozilla/5.0 (Mobile; LYF/F90M/LYF-F90M-000-03-30-101019_i; Android; rv:48.0) Gecko/48.0 Firefox/48.0 KAIOS/2.5"
  //     : navigator.userAgent,
  ua: navigator.userAgent,
  qk: 0,
  ap: 2,
  lc: navigator.language,
  md_language: navigator.language,
  cl: document.documentElement.lang || navigator.languages[navigator.languages.length - 1] || "",
  idfa: "",
  uid: "",
  so: "",
  // mccmnc
  cc: "",
  //mcc
  nc: "",
  //mnc
  imsi: "",
  ifa: "",
  os: 4,
  // User agent check Operating System 1: Android, 2: iOS, 3: msite, 4: Desktop, 5: Kaios
  trq: "",
  md_hr: "",
  md_min: "",
  ai: "",
  aic: "",
  vr: "1.0.0",
  asi: "",
  cgi: "",
  mn: modelExtract(navigator.userAgent),
  md_dvm: modelExtract(navigator.userAgent),
  md_srid: "",
  //Device serial number
  osv: 2.5,
  md_osv: 2.5,
  md_nt: getNetworkType(),
  // network type {WIFI_TYPE = "1", CARRIER_TYPE = "2", IS_2G_TYPE = "3", IS_3G_TYPE = "4", IS_4G_TYPE = "5", IS_5G_TYPE = "6", IS_LAN_TYPE = "7"
  dt: 4,
  // User agent check Device Type
  fn: "0",
  lt: "0",
  sw: window.screen.width,
  sh: window.screen.height,
  se: 45,
  hasParentPkg: false,
  deeplinkingHostName: "dl.kaios.jioads.com",
  IDFAFetchFrequency: 15,
  cacheAdPSExpiry: 0,
  instream: {
    instreamVideoContainer: "test",
    rotateInstreamVideo: false,
    setTimerVisibility: false
  },
  nativeInterstitial: {
    enableControl: false
  },
  logLevel: 1,
  adRequestTimeout: 6000,
  adRenderingTimeout: 5000,
  md_cn: "",
  md_st: "",
  md_ct: "",
  md_pn: "",
  enableAMP: false,
  initialVisibilityAMP: true,
  initialUnMute: false,
  noSkipDisplay: false,
  retPoint: "prod",
  enableLogging: 0,
  autoplay: true,
  locationData: {},
  loc: {},
  spotadEndDelay: 0 // in seconds

}; // const val PIN="md_pn"
// const val CITY="md_ct"
// const val COUNTRY="md_cn"
// const val STATE="md_st"

var STORAGE = new storage();
GLOBAL.locationData = STORAGE.getItem("location_data");
GLOBAL.loc = STORAGE.getItem("location_data");
GLOBAL.vr = "WEB-".concat(GLOBAL.version); // APIVersion

GLOBAL.av = GLOBAL.version;
GLOBAL.priorityEvents = ["APP_LAUNCH", "DEEPLINK_LAUNCH", "ADD_TO_CART", "REMOVE_FROM_CART", "ADD_TO_WISHLIST", "REMOVE_FROM_WISHLIST", "PURCHASE_COMPLETED"];
GLOBAL.nonPriorityEvents = ["CART_VIEW", "PAGE_VIEW", "PRODUCT_LIST_VIEW", "PRODUCT_VIEW", "SEARCH_VIEW", "LOCATION", "CUSTOM", "DEVICE_INFORMATION", "USER_DETAILS", "PRODUCT_CANCELLED", "PRODUCT_RETURNED"];

function getNetworkType() {
  // network type {WIFI_TYPE = "1", CARRIER_TYPE = "2", IS_2G_TYPE = "3", IS_3G_TYPE = "4", IS_4G_TYPE = "5", IS_5G_TYPE = "6", IS_LAN_TYPE = "7"
  var connection = new ConnectionType();
  var type = connection.getConnectionType();

  if (type == 2) {
    var effectivetype = connection.getCellularConnectionType();

    switch (effectivetype) {
      case "slow-2g":
      case "2g":
        return 3;

      case "3g":
        return 4;

      case "4g":
        return 5;
    }
  } else return 1;
} //OS: 1: Android, 2: iOS, 3: msite, 4: Desktop, 5: Kaios


GLOBAL.detectMobileVsDesktopForJioAds = function () {
  var ua = navigator.userAgent;

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    GLOBAL.os = 3;
    GLOBAL.dt = 2;
    GLOBAL.platform = "de";
    return; //tablet
  }

  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    GLOBAL.os = 3;
    GLOBAL.dt = 1;
    GLOBAL.platform = "si";
    return; //Mobile
  }

  GLOBAL.os = 4;
  GLOBAL.dt = 4;
  GLOBAL.platform = "de";
  return; // 'desktop'
}; //// @SET variable OS, DT, AI & AIC


GLOBAL.detectMobileVsDesktopForJioAds();

function endpointConfigure() {
  switch (GLOBAL.reqType) {
    case "stg":
      GLOBAL.url = ["https://mercury-stg.jio.com/delivery/api.php?<zoneid>", "https://mercury-stg.jio.com/delivery/mapi.php?<zoneid>"]; //stg

      break;

    case "prod":
      GLOBAL.url = ["https://mercury.jio.com/delivery/api.php?<zoneid>", "https://mercury.jio.com/delivery/mapi.php?<zoneid>"]; //prod

      break;

    case "dev":
      GLOBAL.url = ["http://49.40.64.244:6080/goapi.php?<zoneid>", "http://49.40.64.244:6080/gomapi.php?<zoneid>"]; //dev

      break;

    case "sit":
      GLOBAL.url = ["http://35.207.207.48/delivery/api.php?<zoneid>", "http://35.207.207.48/delivery/mapi.php?<zoneid>"]; //sit

      break;
  }
}

endpointConfigure();
GLOBAL.url_ret = "v1/retarget/data"; //"https://pp-jars-zircon.jio.com/v1/retarget/data"; //public url

GLOBAL.url_ret_Gzip = "v1/retarget/data/compressed"; //"http://49.40.49.97/v1/retarget/data/compressed"; //public url

GLOBAL.conversionUrl = "https://mercury-ck.jio.com/delivery/cn.php?";
GLOBAL.cdnUrl = "https://mercury.akamaized.net/beacon/kaib.gif?";
GLOBAL.initUrl = "http://api.jio.com/v2/users/me?app-name=RJIL_JioAds_KaiOS";
GLOBAL.errorLogging = "https://jioads.akamaized.net/err/com.jio.store/an_err.gif?";

GLOBAL.setApplicationConfiguration = function (options) {
  // GLOBAL = { ...GLOBAL, ...options };
  if (options.hasOwnProperty("logLevel")) {
    GLOBAL.logLevel = options["logLevel"];
    SDK.setLogLevel(GLOBAL.logLevel);
  }

  if (options.hasOwnProperty("ai")) {
    GLOBAL.hasParentPkg = true;
  }

  if (options.hasOwnProperty("lo")) {
    GLOBAL.hasLocationSet = true;
  } else {
    if (!GLOBAL.hasLocationSet) {
      LOCATION.locationHandler();
    }
  }

  if (options.hasOwnProperty("isJioNetwork") && !options.hasOwnProperty("uid")) {
    INIT.JioUidHandler();
  }

  if (!options.hasOwnProperty("ifa")) {
    if (!GLOBAL.ifa) {
      var identifiers = null;

      if (GLOBAL.identifiers) {
        identifiers = GLOBAL.identifiers;
      } else {
        identifiers = options["identifiers"];
      }

      var uidFetcher = new JioAdsUidGenerator({
        identifiers: identifiers
      });
    }
  }

  Object.assign(GLOBAL, options);
  endpointConfigure();
};

GLOBAL.setConversion = function (cid, level, options, url) {
  var id = cid;
  var clevel = level;
  var mdata = options;
  var ep = url || GLOBAL.conversionUrl;
  var configr = "";
  configr = mdata ? "".concat(ep, "cid=").concat(id, "&level=").concat(clevel, "&").concat(GLOBAL.obj2URLEncode(mdata)) : "".concat(ep, "cid=").concat(id, "&level=").concat(clevel);
  var xhc = new XMLHttpRequest();
  xhc.open("GET", configr, true);
  xhc.send();
};

GLOBAL.obj2URLEncode = function (obj) {
  var objArr = [];
  var excludeItems = ["extra"];

  if (_typeof(obj) === "object") {
    for (var ix in obj) {
      if (obj.hasOwnProperty(ix) && excludeItems.indexOf(ix) == -1) objArr.push(ix + "=" + obj[ix]);
    }

    return encodeURI(objArr.join("&"));
  } else return "";
};

function modelExtract(li) {
  var st = li.split("LYF");
  var ft = st.length > 1 ? st[1].slice(1, st[1].length - 1) : "";
  return ft;
}

function touchPhone() {
  var width = window.screen.width;
  var height = window.screen.height;

  if (height == 480 || width == 480 || height == 408 || width == 408) {
    return true;
  }

  return false;
}

if (typeof window.isAdBlockActive !== "undefined") {
  window.isAdBlockActive = false;
}

GLOBAL.adObject = {};
GLOBAL.AdManager = {};
GLOBAL.ViewStreams = {};
GLOBAL.PreFetchManger = {};
GLOBAL.ProductionAd = new storageManager("jio_production_ad");
GLOBAL.PrefetchAd = new storageManager("jio_prefetch_ad");
GLOBAL.AdLoggerManager = {};
GLOBAL.EventStorageManager = new storageManagerRe("jio_ads_event_log");
export default GLOBAL;