import GLOBAL from "../utils/global";

var DESTROY = function DESTROY(placementId) {
  if (GLOBAL.AdManager.hasOwnProperty(placementId)) {
    if (GLOBAL.adObject.hasOwnProperty(placementId) && GLOBAL.adObject[placementId]["type"] == 5) {
      if (GLOBAL.adObject[placementId].getVideoContainer()) GLOBAL.adObject[placementId].getVideoContainer().innerHTML = "";
    }

    try {
      GLOBAL.adObject[placementId].destroy();
    } catch (err) {}

    delete GLOBAL.AdManager[placementId];
    delete GLOBAL.adObject[placementId];
  }

  if (GLOBAL.ViewStreams.hasOwnProperty(placementId)) {
    delete GLOBAL.ViewStreams[placementId];
  }
};

export default DESTROY;