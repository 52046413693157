import SDK from "./../../utils/SDKLog";
import ERROR from "./../../utils/error";
import CALLBACK from "./../../interface/callback";

var noAdFound = function noAdFound(options) {
  var status = options.status,
      errorCode = options.errorCode,
      errorDesc = options.errorDesc,
      data = options.data;
  var placementId = data.placementId;
  SDK.log("failed to fetch Ad");
  CALLBACK.onAdFailedToLoad({
    placementId: placementId,
    errorCode: errorCode,
    response: status,
    methodname: "noAdFound"
  });
};

export default noAdFound;