var TRACKER = {};
import GLOBAL from "./global";
import UTILS from "./util";

TRACKER.impression = function (options) {
  var id = options.id,
      URL = options.URL,
      domElement = options.domElement,
      ccb = options.ccb,
      _options$isClick = options.isClick,
      isClick = _options$isClick === void 0 ? true : _options$isClick,
      adsize = options.adsize,
      _options$seq = options.seq,
      seq = _options$seq === void 0 ? 0 : _options$seq;
  var pId = domElement ? document.body : document.getElementById(id);
  var frame = document.createElement("iframe");
  var randomId = domElement ? id + "_" + Math.random(10) * 100 : Math.random(10) * 100;
  frame.id = randomId;
  frame.style = "width: 0px; height: 0px; border: 0;";
  var nodes = [];

  if (URL.length > 0) {
    for (var i in URL) {
      var rUrl = URL[i];
      var size = id ? GLOBAL.adObject[id].adSize : adsize;
      var macrosval = {
        bz: size,
        ccb: ccb,
        seq: seq
      };
      UTILS.generateMacrosList(macrosval, id, isClick && domElement);
      rUrl = UTILS.replaceMacrosValues(rUrl, macrosval, isClick);
      nodes.push('<img src="' + rUrl + '" width="1" height="1" border="0">');
    }
    /*frame.src = "data:text/html," + encodeURIComponent(nodes.join(""));*/


    pId.insertBefore(frame, null);
    var doc = document.getElementById(randomId).contentWindow.document;
    doc.open();
    doc.write("<body>" + nodes.join("") + "</body>");
    doc.close();
  }
};

TRACKER.cleanDocument = function (id) {
  var frameList = document.querySelectorAll("[id^=" + id + "_" + "]");

  if (frameList.length) {
    Array.prototype.forEach.call(frameList, function (node) {
      node.parentNode.removeChild(node);
    });
  }
};

export default TRACKER;