function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

import storage from "./../../utils/storage";
import SDK from "./../../utils/SDKLog";
import UTILS from "./../../utils/util";
import DOM from "./../../utils/dom";
import _ from "object-path";
var FCAP = {
  STORAGE: new storage(),
  setValue: function setValue(propertyPath, value, obj) {
    var properties = Array.isArray(propertyPath) ? propertyPath : propertyPath.split(".");

    if (properties.length > 1) {
      if (!obj.hasOwnProperty(properties[0]) || _typeof(obj[properties[0]]) !== "object") obj[properties[0]] = {};
      return this.setValue(properties.slice(1), value, obj[properties[0]]);
    } else {
      obj[properties[0]] = value;
      return true;
    }
  },
  addCampaign: function addCampaign(campaignid, options) {
    var campaignList = FCAP.STORAGE.getItem("campaignList", "local") || {},
        serverExpiryTime = FCAP.STORAGE.getItem("serverExpiryTime", "local") || {},
        date = new Date(),
        i = options["i"],
        c = options["c"],
        cv = options["cv"],
        campaignExpiry = parseInt(options["expiry"]) || 30,
        //default 30 days
    id = campaignid;

    this.setCounter = function (value, type) {
      if (value && value.hasOwnProperty("m")) this.setValue(id + "." + type + ".m", null, campaignList);
      if (value && value.hasOwnProperty("h")) this.setValue(id + "." + type + ".h", null, campaignList);
      if (value && value.hasOwnProperty("d")) this.setValue(id + "." + type + ".d", null, campaignList);
      if (value && value.hasOwnProperty("l")) this.setValue(id + "." + type + ".l", null, campaignList);
    };

    if (campaignList.hasOwnProperty(id)) {
      if (campaignList[id]["i"]["hash"] !== JSON.stringify(i)) {
        delete campaignList[id]["i"];
        this.setCounter(i, "i");
        serverExpiryTime["c_" + id] = options;
      }

      if (campaignList[id]["c"]["hash"] !== JSON.stringify(c)) {
        delete campaignList[id]["c"];
        this.setCounter(c, "c");
        serverExpiryTime["c_" + id] = options;
      }

      if (campaignList[id]["cv"]["hash"] !== JSON.stringify(cv)) {
        delete campaignList[id]["cv"];
        this.setCounter(c, "cv");
        serverExpiryTime["c_" + id] = options;
      }
    } else {
      this.setCounter(i, "i");
      this.setCounter(c, "c");
      this.setCounter(cv, "cv");
    }

    serverExpiryTime["c_" + id] = options;
    this.setValue(id + ".i.hash", JSON.stringify(i), campaignList);
    this.setValue(id + ".c.hash", JSON.stringify(c), campaignList);
    this.setValue(id + ".cv.hash", JSON.stringify(cv), campaignList);
    this.setValue(id + ".expireTime", date.setDate(date.getDate() + campaignExpiry) - (date.getHours() * 60 * 60 * 1000 + date.getMinutes() * 60 * 1000 + date.getSeconds() * 1000), campaignList);
    FCAP.STORAGE.setItem("campaignList", campaignList, "local");
    FCAP.STORAGE.setItem("serverExpiryTime", serverExpiryTime, "local");
    SDK.log("FCAP Campaign Expiry Time :  ".concat(new Date(campaignList[id]["expireTime"])));
  },
  cleanUp: function cleanUp(option) {
    var self = this;
    this.id = option && option["id"] ? option["id"] : "";
    this.type = option && option["type"] ? option["type"] : "";
    this.campaignList = FCAP.STORAGE.getItem("campaignList", "local") || {};
    this.localStorageServerExpiryTime = FCAP.STORAGE.getItem("serverExpiryTime", "local");

    this.get = function () {
      return this.validate(this.type !== "" ? this.campaignList[this.id] : this.id == "" ? this.campaignList : UTILS.pick(this.campaignList, [this.id]));
    };

    this.storeAndReturn = function () {
      if (this.id || this.type) return false;
      this.campaignList = this.get();
      FCAP.STORAGE.setItem("campaignList", this.campaignList, "local");
      return this.campaignList;
    };

    this.validate = function (options) {
      // var currentTime = Date.parse(new Date()),
      var currentTime = Date.hasOwnProperty("now") ? Date.now() : new Date().getTime(),
          total = null,
          ignoreCampaignList = JSON.parse(sessionStorage.getItem("ignoreCampaignList")) || [],
          isExpiryKeyInCampaign = this.campaignList.hasOwnProperty(this.id) && this.campaignList[this.id].hasOwnProperty(this.type) && this.campaignList[this.id][this.type].hasOwnProperty("expireTime");
      var campaign = options;

      if (option && this.id && this.type && !isExpiryKeyInCampaign) {
        return campaign;
      }

      if (this.type !== "") {
        for (var key in campaign[this.type]) {
          if (currentTime > campaign[this.type]["expireTime"][key]) {
            campaign[this.type][key] = 0;
            delete campaign[this.type]["expireTime"][key];
          }
        }
      } else {
        for (var id in campaign) {
          for (var type in campaign[id]) {
            if (type.match(/^(i|c|cv)$/)) {
              for (var counter in campaign[id][type]) {
                if (campaign[id][type].hasOwnProperty("expireTime") && counter.match(/^(m|h|d)$/)) {
                  if (currentTime > campaign[id][type]["expireTime"][counter]) {
                    campaign[id][type][counter] = 0;
                    ignoreCampaignList.splice(ignoreCampaignList.indexOf(campaign), 1);
                    sessionStorage.setItem("ignoreCampaignList", JSON.stringify(ignoreCampaignList));
                    delete campaign[id][type]["expireTime"][counter];
                  }
                }

                if (counter.match(/^(m|h|d|l)$/)) {
                  if (typeof campaign[id][type][counter] === "number") {
                    total += campaign[id][type][counter];
                  }
                }
              }
            }
          }

          if (currentTime > campaign[id]["expireTime"] || total === 0) {
            delete campaign[id];
            delete this.localStorageServerExpiryTime["c_" + id];
            FCAP.STORAGE.setItem("serverExpiryTime", this.localStorageServerExpiryTime, "local");
          }
        }
      }

      return campaign;
    };
  },
  incrementCounter: function incrementCounter(options) {
    this.setExpiryTime = function (data) {
      if (data) {
        var val = data,
            expiry = {};

        if (val && val.hasOwnProperty("m")) {
          var min = new Date();
          expiry["m"] = min.setMinutes(min.getMinutes() + parseInt(val["m"])) - min.getSeconds() * 1000;
        }

        if (val && val.hasOwnProperty("h")) {
          var hour = new Date();
          expiry["h"] = hour.setHours(hour.getHours() + parseInt(val["h"])) - (hour.getMinutes() * 60 * 1000 + hour.getSeconds() * 1000);
        }

        if (val && val.hasOwnProperty("d")) {
          var date = new Date();
          expiry["d"] = date.setDate(date.getDate() + parseInt(val["d"])) - (date.getHours() * 60 * 60 * 1000 + date.getMinutes() * 60 * 1000 + date.getSeconds() * 1000);
        }

        return expiry;
      }
    };

    this.setExpiryTimeByType = function (expiryType) {
      var campaignType = localStorageServerExpiryTime["c_" + campaignId] ? localStorageServerExpiryTime["c_" + campaignId][type] : null;
      if (!campaignType) return;

      var getCounterValue = _defineProperty({}, expiryType, campaignType[expiryType]);

      if (getCounterValue[expiryType] != undefined || getCounterValue[expiryType] != null) {
        var counterExpiryTime = this.setExpiryTime(getCounterValue)[expiryType];
        this.setValue(campaignId + "." + type + ".expireTime." + expiryType, counterExpiryTime, campaignList);
      }
    };

    this.print = function () {
      SDK.log("FCAP Cuurent Time :  ".concat(new Date()));

      for (var key in campaignList[campaignId][type]["expireTime"]) {
        SDK.debug("FCAP for ".concat(type.repeat(3).toUpperCase(), " :=: ").concat(key.toUpperCase(), " : ").concat(new Date(campaignList[campaignId][type]["expireTime"][key])));
      }

      SDK.log("campaignList " + JSON.stringify(campaignList));
    };

    if (options) {
      var type = options["type"],
          campaignList = FCAP.STORAGE.getItem("campaignList", "local"),
          localStorageServerExpiryTime = FCAP.STORAGE.getItem("serverExpiryTime", "local"),
          campaignId = options.campaignId;
      campaignList[campaignId] = new this.cleanUp({
        id: campaignId,
        type: type
      }).get();

      if (!campaignList[campaignId][type].hasOwnProperty("expireTime")) {
        var camp = localStorageServerExpiryTime["c_" + campaignId] ? localStorageServerExpiryTime["c_" + campaignId][type] : null;
        if (camp) campaignList[campaignId][type]["expireTime"] = this.setExpiryTime(camp);
      } else {
        var expiry = campaignList[campaignId][type]["expireTime"];

        if (!expiry.hasOwnProperty("m")) {
          this.setExpiryTimeByType("m");
        }

        if (!expiry.hasOwnProperty("h")) {
          this.setExpiryTimeByType("h");
        }

        if (!expiry.hasOwnProperty("d")) {
          this.setExpiryTimeByType("d");
        }
      }

      this.print();

      for (var key in campaignList[campaignId][type]) {
        if (key.match(/^(m|h|d|l)$/)) {
          campaignList[campaignId][type][key] += 1;
        }
      }

      FCAP.STORAGE.setItem("campaignList", campaignList, "local");
    }
  },
  getCampaignList: function getCampaignList() {
    var list = this.clean();

    for (var campaign in list) {
      if (list[campaign].hasOwnProperty("i")) {
        if (list[campaign]["i"].hasOwnProperty("expireTime")) {
          delete list[campaign]["i"]["expireTime"];
        }

        if (list[campaign]["i"].hasOwnProperty("hash")) {
          delete list[campaign]["i"]["hash"];
        }
      }

      if (list[campaign].hasOwnProperty("c")) {
        if (list[campaign]["c"].hasOwnProperty("expireTime")) {
          delete list[campaign]["c"]["expireTime"];
        }

        if (list[campaign]["c"].hasOwnProperty("hash")) {
          delete list[campaign]["c"]["hash"];
        }
      }

      if (list[campaign].hasOwnProperty("cv")) {
        if (list[campaign]["cv"].hasOwnProperty("expireTime")) {
          delete list[campaign]["cv"]["expireTime"];
        }

        if (list[campaign]["cv"].hasOwnProperty("hash")) {
          delete list[campaign]["cv"]["hash"];
        }
      }

      if (list[campaign].hasOwnProperty("expireTime")) delete list[campaign]["expireTime"];
    }

    return list;
  },
  isFrequencyCapped: function isFrequencyCapped(options) {
    var selectedCampaign = options.selectedCampaign,
        limit = options.limit;
    var filteredCampaign = new this.cleanUp({
      id: selectedCampaign
    }).get()[selectedCampaign] || {};
    var ignoreCampaignList = JSON.parse(sessionStorage.getItem("ignoreCampaignList")) || [];
    var data = {
      limit: limit,
      i: filteredCampaign["i"] || {},
      c: filteredCampaign["c"] || {},
      cv: filteredCampaign["cv"] || {}
    };
    var capped = false;

    for (var key in data["limit"]) {
      var m = data[key].hasOwnProperty("m") && data[key]["m"];
      var d = data[key].hasOwnProperty("d") && data[key]["d"];
      var h = data[key].hasOwnProperty("h") && data[key]["h"];
      var l = data[key].hasOwnProperty("l") && data[key]["l"];

      if (data["limit"][key].hasOwnProperty("l") && data["limit"][key]["l"] <= l) {
        capped = true;
        SDK.log("Inside L");
        break;
      } else if (data["limit"][key].hasOwnProperty("m") && data["limit"][key]["m"] <= m) {
        SDK.log("Inside M");
        capped = true;
        break;
      } else if (data["limit"][key].hasOwnProperty("h") && data["limit"][key]["h"] <= h) {
        capped = true;
        SDK.log("Inside H");
        break;
      } else if (data["limit"][key].hasOwnProperty("d") && data["limit"][key]["d"] <= d) {
        capped = true;
        SDK.log("Inside D");
        break;
      }
    }

    if (capped) {
      ignoreCampaignList.push(selectedCampaign);
      sessionStorage.setItem("ignoreCampaignList", JSON.stringify(ignoreCampaignList));
    }

    return capped;
  },
  getIgnoreCampaignList: function getIgnoreCampaignList() {
    this.clean();
    return JSON.parse(sessionStorage.getItem("ignoreCampaignList")) || [];
  },
  init: function init(options) {
    if (options) {
      var pId = options.pId,
          type = options.type,
          _options$campaignId = options.campaignId,
          campaignId = _options$campaignId === void 0 ? false : _options$campaignId;
      var ins_tag = DOM.getElement(pId);

      if (campaignId || ins_tag && ins_tag.hasAttribute("data-fcap")) {
        this.incrementCounter({
          campaignId: campaignId || ins_tag.getAttribute("data-fcap"),
          type: type
        });
      }
    }
  },
  clean: function clean() {
    return new FCAP.cleanUp().storeAndReturn();
  }
};
export default FCAP;