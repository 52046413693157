import inView from "./inView";
import SDK from "./../../utils/SDKLog";
import Timeout from "./timeoutHandler";
import CALLBACK from "./../../interface/callback";
import INIT from "./../../initservices/init";
import UTILS from "../../utils/util";
import GLOBAL from "../../utils/global";

var InViewHandler = function InViewHandler(options) {
  var placementId = options.placementId,
      _options$callback = options.callback,
      callback = _options$callback === void 0 ? function () {} : _options$callback,
      _options$viewableCB = options.viewableCB,
      viewableCB = _options$viewableCB === void 0 ? function () {} : _options$viewableCB,
      _options$firstTimePla = options.firstTimePlay,
      firstTimePlay = _options$firstTimePla === void 0 ? function () {} : _options$firstTimePla,
      _options$resume = options.resume,
      resume = _options$resume === void 0 ? function () {} : _options$resume,
      _options$pause = options.pause,
      pause = _options$pause === void 0 ? function () {} : _options$pause,
      refreshTime = options.refreshTime;
  var id = "#".concat(placementId);
  refreshTime = (refreshTime == 0 || refreshTime == null ? 0 : (refreshTime < 30 ? 30 : refreshTime) * 1000) || 0;
  SDK.debug("InViewHandler ".concat(placementId, " Ad Refresh In ").concat(refreshTime / 1000, " Sec"));
  var timestamp = new Date().getTime();
  var element = document.querySelector(id).firstElementChild;

  if (!element) {
    var placementNode = document.getElementById(placementId);
    var instream = placementNode.getAttribute("data-container-id");

    if (instream) {
      element = document.getElementById(instream);
      element.setAttribute("data-placementId", placementId);
    }
  }

  element.setAttribute("data-viewId", timestamp);
  var type = element.tagName.toLowerCase();
  var selector = "".concat(type, "[data-viewId=\"").concat(timestamp, "\"]");
  var vImpression = false;
  var vTimeout = 0;
  new inView({
    enter: function enter(el) {
      SDK.log("enter Becaon Fire for preexisting element");

      if (GLOBAL.enableAMP == true) {
        if (GLOBAL.initialVisibilityAMP == true) {
          firstTimePlay();
          callback();
        }
      } else {
        firstTimePlay();
        callback();
      }

      if (refreshTime > 0) {
        SDK.log("Ad: In View handler : ".concat(el.parentElement["id"]));

        if (Timeout.exists(selector)) {
          Timeout.resume(selector);
          resume();
        } else {
          Timeout.set(selector, function () {
            Timeout.clear(selector);
            var selectedId = null;
            var selectedElement = document.querySelector(selector);

            if (selectedElement && selectedElement.parentElement) {
              selectedId = selectedElement.parentElement.getAttribute("id");
            }

            if (!selectedId && selectedElement) {
              selectedId = selectedElement.getAttribute("data-placementId");
            }

            if (selectedElement && UTILS.overlayInviewDetect(selectedElement)) {
              CALLBACK.onAdRefresh({
                placementId: selectedId
              });
              INIT.renderAd(selectedId);
            }
          }, refreshTime);
        }
      }
      /****Viewable Impression Start****/


      if (!vImpression) {
        vTimeout = setTimeout(function () {
          viewableCB();
          SDK.log("@@@@@ Timeout viewable is working @@@@@");
          vImpression = true;
        }, "1000");
      }
      /****Viewable Impression End****/

    },
    exit: function exit(el) {
      SDK.log("exit");
      /****Viewable Impression Clear****/

      clearTimeout(vTimeout);
      /****Viewable Impression Clear****/

      if (el.parentElement != null) {
        SDK.log("Ad: Not In View handler : ".concat(el.parentElement["id"]));
      }

      if (GLOBAL.enableAMP == true) {
        if (GLOBAL.initialVisibilityAMP == true) {
          pause();
        }
      } else {
        pause();
      }

      if (refreshTime > 0) {
        if (el.parentElement != null) {
          SDK.log("Ad: Not In View handler : ".concat(el.parentElement["id"]));
        }

        Timeout.pause(selector);
        pause();
      }
    },
    once: function once(el) {
      SDK.log("once");
      SDK.log("Becaon Fire when element came to inview: ".concat(el.parentElement["id"]));

      if (GLOBAL.enableAMP == true) {
        if (GLOBAL.initialVisibilityAMP == true) {
          firstTimePlay();
          callback(el.parentElement["id"]);
        }
      } else {
        firstTimePlay();
        callback(el.parentElement["id"]);
      }
    },
    selector: selector
  });
};

export default InViewHandler;